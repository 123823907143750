import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useBrowser } from "../pages/Provider/BrowserContext";

const CardColumns = ({
  className = "",
  cardColumnOne,
  staticWebsiteDevelopment,
  fastSecureAndSEOFriendlyS,
  toLink,
}) => {
  const w = useBrowser().windowDimensions.width
  return (
    <div className={`flex flex-col mqAllMobile:flex-row items-start justify-between py-0 px-4 mqAllMobile:px-2 box-border gap-8 text-left text-lg text-white font-montserrat mqAllMobile:gap-4 mqAllMobile:items-center mqAllMobile:!w-full ${className}`}>
      {/* Image */}
      {w > 550 ? <img
        className="h-[400px] mqAllMobile:w-[40%] !w-[90%] mqAllMobile:!h-[40%] self-center object-cover rounded-md"
        loading="lazy"
        alt={staticWebsiteDevelopment}
        src={cardColumnOne}
      /> : <div className="mqAllMobile:w-[200%]">
        <img
          className="!w-[100%] self-center object-cover rounded-md"
          loading="lazy"
          alt={staticWebsiteDevelopment}
          src={cardColumnOne}
        />
      </div>}
      {/* Text Content */}
      <div className="flex flex-col items-start justify-start gap-6 mqAllMobile:gap-2 text-center mq450:text-left">
        <h1 className="m-0 self-stretch relative text-inherit font-normal font-inherit mq825:text-7xl mqAllMobile:!text-lg mqAllMobile:!text-start">
          {staticWebsiteDevelopment}
        </h1>
        <p className="m-0 self-stretch relative text-base font-poppins text-lightgray-100 text-justify mqAllMobile:text-smi mqAllMobile:!text-start">
          {fastSecureAndSEOFriendlyS}
        </p>
        {/* Learn More Button */}
        <button className="cursor-pointer py-2 pr-[54px] pl-[55px] bg-[transparent] rounded-31xl overflow-hidden flex flex-row items-start justify-start whitespace-nowrap border-[1px] border-solid border-white hover:bg-gainsboro-300 hover:border-gainsboro-100 mqAllMobile:px-3 mqAllMobile:py-1">
          <Link to={toLink} className="no-underline relative text-base font-poppins text-white inline-block min-w-[89px] mqAllMobile:!text-smi">
            Learn More
          </Link>
        </button>
      </div>
    </div>
  );
};

CardColumns.propTypes = {
  className: PropTypes.string,
  cardColumnOne: PropTypes.string.isRequired,
  staticWebsiteDevelopment: PropTypes.string.isRequired,
  fastSecureAndSEOFriendlyS: PropTypes.string.isRequired,
  toLink: PropTypes.string.isRequired,
};

export default CardColumns;