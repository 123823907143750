import React from 'react'

function OurExpertise() {
    const dataShowComp = ({ title, desc, key }) => <div className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-3 gap-[4px]" key={key}>
        <h3 className="m-0 self-stretch relative text-inherit font-normal font-inherit z-[1] mq450:text-lgi font-montserrat text-white mqAllMobile:text-lg">
            {title}
        </h3>
        <div className="w-[305px] h-0.5 relative box-border z-[1] border-t-[2px] border-solid border-midnightblue" />
        <p className="m-0 self-stretch relative text-base text-gray-400 text-justify z-[1] font-poppins mqAllMobile:text-smi">
            {desc}
        </p>
    </div>
    const AllData = [{ title: "HTML5 and CSS3", desc: "The foundation of modern web development, ensuring your site is up-to-date with the latest standards." }, { title: "JavaScript and React", desc: "For adding interactivity and enhancing user experience." }, { title: "Responsive Design", desc: "Ensuring your website looks great on all devices, from desktops to mobile phones." }, { title: "Version Control", desc: "Using Git for efficient project management and collaboration." }, { title: "Hosting Solutions", desc: "We provide advice on the best hosting solutions for static websites, including GitHub Pages, Netlify, and Vercel." }]
    return (
        <div className="w-[630px] flex flex-col items-start justify-start gap-[34px] min-w-[630px] shrink-0 [debug_commit:bf4bc93] max-w-full mq900:gap-[17px] mq900:max-w-full mq900:w-[calc(100vw-40px)] mq750:min-w-[325px] mq450:mx-0 mq1325:flex-1 mq1250:mx-5 mq750:!max-w-[500px] ">
            <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-2.5 mqAllMobile:!px-2.5 box-border max-w-full text-45xl font-montserrat">
                <h1 className="m-0 flex-1 relative text-inherit font-bold font-inherit inline-block max-w-full z-[1] mq900:text-32xl text-white mqAllMobile:!text-3xl">
                    Our Expertise and Technologies
                </h1>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-3 box-border gap-[46px] max-w-full mq900:gap-[23px] mqAllMobile:!pb-0">
                <div className="self-stretch flex flex-row items-start justify-start pt-0 pb-2.5 pr-0 pl-2.5 mqAllMobile:!px-2.5 box-border max-w-full text-justify text-base text-gray-400">
                    <p className="m-0 flex-1 relative inline-block max-w-full z-[1] font-poppins mqAllMobile:text-smi">
                        We leverage the latest technologies and frameworks to build
                        robust static websites. Our expertise includes:
                    </p>
                </div>
            </div>
            <div className='flex flex-col gap-[34px] mq900:gap-[17px] mqAllMobile:!gap-1 mb-3'>
                {AllData.map((data, index) => dataShowComp({ ...data, key: index }))}
            </div>
        </div>
    )
}

export default OurExpertise