import PropTypes from "prop-types";
import { Link } from "react-router-dom"

const FullStackHeadComponent = ({ className = "" }) => {
  return (
    <div
      className={`self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[50px] box-border max-w-full text-left text-light-100 font-montserrat mq900:pb-[21px] mq900:box-border mq1325:pb-8 mq1325:box-border mq550:px-2 mq450:px-0 ${className}`}
    >
      <div className="w-[1400px] flex flex-row items-start justify-start gap-[91px] max-w-full mq450:gap-[23px] mq900:gap-[45px] mq1920:flex-wrap //mq1725:flex-wrap">
        <div className="flex-1 flex flex-col items-start justify-start gap-[50px] min-w-[572px] max-w-full mqAllMobile:gap-[15px] mq900:min-w-full">
          <b className="self-stretch relative z-[1] mq450:text-23xl mq900:text-37xl //mq1550:text-center text-center mqAllMobile:!text-4xl text-51xl-5">
            Let’s talk about our full stack development.
          </b>
          <div className="w-full flex justify-center items-center">
            <div className=" max-w-[1200px] [backdrop-filter:blur(16px)] rounded-21xl bg-gainsboro-400 box-border flex flex-col items-center justify-center p-[60px] mqAllMobile:!py-[15px] mqAllMobile:!px-[5px] gap-[54px] mq900:gap-[40px] w-full z-[1] border-[3px] border-solid border-gray-1000 mq450:pt-[27px] mq450:pb-6 mq450:box-border mq900:pl-[35px] mq900:pr-8 mq900:box-border mq1325:pt-[41px] mq1325:pb-[37px] mq1325:box-border mqAllMobile:!gap-[25px]">
              {/* 825 */}
              <div className="flex flex-row items-start justify-start py-0 pr-0 pl-0.5">
                <h1 className="m-0 relative text-inherit font-bold font-inherit z-[1] mq825:text-9xl !text-15xl mqAllMobile:!text-xl text-center mqAllMobile:px-1">
                  Our Full Stack Website Development Process
                </h1>
              </div>
              <div className="mq825:w-[662px] w-full flex flex-row items-start justify-start max-w-full text-center text-lgi-5 font-adamina">
                <div className="flex-1 flex flex-col items-start justify-start px-0 pb-0 box-border text-left text-smi font-poppins">
                  {[
                    {
                      title: "Understanding Your Needs",
                      description:
                        "We begin by thoroughly understanding your business goals, target audience, and specific requirements. Our team conducts detailed consultations to gather all the necessary information to ensure we create a website that perfectly aligns with your vision.",
                    },
                    {
                      title: "Content Creation",
                      description:
                        "High-quality content is crucial for any website. Our content creators work closely with you to develop engaging and informative content that resonates with your audience. Whether it’s writing copy for your homepage, services pages, or blog posts, we ensure your message is clear and compelling.",
                    },
                    {
                      title: "Planning and Design",
                      description:
                        "Next, we move on to the planning and design phase. Our designers craft visually appealing layouts that are not only aesthetically pleasing but also user-friendly. We focus on creating a seamless user experience with intuitive navigation and responsive design that looks great on all devices.",
                    },
                    {
                      title: "Development",
                      description:
                        "In the development phase, our developers bring the designs to life using the latest technologies and best practices. We focus on creating clean, efficient, and SEO-friendly code to ensure your website performs optimally and ranks well in search engines.",
                    },
                    {
                      title: "Testing and Quality Assurance",
                      description:
                        "Before launching, we conduct thorough testing to ensure your website is free of bugs and performs seamlessly across different browsers and devices. Our quality assurance team checks every aspect of the site to ensure it meets our high standards.",
                    },
                    {
                      title: "Launch and Deployment",
                      description:
                        "Once everything is perfect, we launch your website. Our team handles the entire deployment process, ensuring a smooth transition from development to a live environment. We also provide support during the launch phase to address any immediate issues.",
                    }
                  ].map(({ title, description }, i) => (
                    <div className="flex" key={i}>
                      <div
                        className="rounded-[9.75px] [background:linear-gradient(270deg,_#ff009b,_#7f00ff)] flex flex-row items-center justify-start pt-[1.6px] pb-[2.3px] pr-2.5 pl-[13px] z-[1] max-h-[30px] mq575:max-h-[20px] mq825:mr-5 mr-3 mqAll:ml-[-20px] mq575:mr-2"
                      >
                        <div className="relative inline-block min-w-[22px] text-2xl z-[2] mq575:min-w-[18px] mq575:text-base ">
                          {`0${i + 1}`}
                        </div>
                      </div>
                      <div className="self-stretch flex flex-col items-start justify-start gap-[21.1px] max-w-full pb-[20px]">
                        <div className="w-full flex flex-col items-start justify-start py-0 px-px box-border max-w-full">
                          <div className="flex-1 flex flex-col items-start justify-start gap-[9.4px] max-w-full">
                            <div className="relative shrink-0 z-[1] text-[24px] mq825:text-xl mqAllMobile:text-mid">{title}</div>
                            <p className="m-0 self-stretch relative leading-[24px] ml-[-40px] mqAllMobile:ml-[-20px] mq825:leading-[17.06px] text-gray-200 shrink-0 z-[1] mq825:text-lg mqAllMobile:!text-smi text-[18px]">
                              {description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[773px] flex flex-col items-start justify-start gap-[120px] min-w-[773px] max-w-full text-center text-4xl text-gray-400 font-poppins mq450:gap-[23px] mq900:min-w-full //mq1725:flex-1 mq1920:flex-1 mt-12 mq1550:mt-0">
          <div className="flex flex-col items-start justify-start w-full">
            <h2 className="m-0 self-stretch h-[48.9px] relative text-inherit font-bold font-inherit inline-block shrink-0 z-[1] mqAllMobile:text-lg mqAllMobile:h-fit mqAllMobile:mb-2">
              Making complex websites simple.
            </h2>
            <div className="self-stretch flex flex-row items-start justify-center py-0 px-5 text-lgi  mt-10 mqAllMobile:mt-0">
              <Link to="/pricing" className="w-[222px] no-underline rounded-3xs bg-darkorchid-200 text-light-100 flex flex-row items-center justify-center py-[15px] box-border shrink-0 z-[1] whitespace-nowrap mqAllMobile:w-fit mqAllMobile:text-mid mqAllMobile:px-3 mqAllMobile:py-1">
                <h3 className="m-0 h-[38px] flex relative text-inherit font-normal font-inherit items-center justify-center text-center z-[1]">
                  Get Started Today
                </h3>
              </Link>
            </div>
          </div>
          <div className="self-stretch flex justify-center items-center mq1920:hidden">
            <img className="!w-[1373px] !ml-[-600px]" loading="lazy" alt="" src="/imgheader.svg" />
          </div>
        </div>
      </div>
    </div>
  );
};

FullStackHeadComponent.propTypes = {
  className: PropTypes.string,
};

export default FullStackHeadComponent;
