import { Footer } from "../components/FooterTop";
import Header from "../components/Header";
import FullStackHeadComponent from "../components/FullStackHeadComponent.js";
import PackageFeatures from "../components/PackageFeatures";
import PricingStartsFrom from "../components/PricingStartsFrom.js";
import Plans from "../components/Plans.js";

const VCTFullStack = () => {
  return (
    <div className="w-full relative min-h-screen bg-black overflow-hidden flex flex-col items-start justify-start leading-[normal] tracking-[normal]">
      <div className="w-[2487px] h-[7910px] absolute !m-[0] bottom-[-3620px] left-[-291px] flex items-center justify-center z-[0]">
        <img
          className="w-full h-full object-contain absolute left-[0px] top-[-86px] [transform:scale(1.022)]"
          alt=""
          src="/element.svg"
        />
      </div>
      <Header />
      <div className="self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[9px] box-border max-w-full mt-5 mqAllMobile:mt-0">
        <div className="flex flex-col items-start justify-start gap-[50px] max-w-full mq900:gap-[25px]">
          <FullStackHeadComponent />
        </div>
      </div>
      <div className="flex-1 w-full justify-center items-center">
        <div className="self-stretch flex flex-col items-center justify-center py-0 px-5 font-montserrat my-12 text-45xl text-light-100 mqAllMobile:mt-2 mqAllMobile:mb-0">
          <h1 className="m-0 relative text-inherit font-bold font-inherit z-[1] mq450:text-19xl mq900:text-32xl text-center mqAllMobile:!text-4xl ">
            The Ultimate Package
          </h1>
          <p className="text-lg z-[1] text-center mqAllMobile:text-mid">
            These are just some of the features we can offer as part of our enterprise packages for your full stack web application needs.
          </p>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-[11px] box-border gap-[50px] max-w-full text-3xl-3 mq900:gap-[25px]">
          <PackageFeatures data={[{ title: "User Authentication and Authorisation", desc: "Secure access to the application by implementing robust user authentication and role-based authorisation systems.", image1: "/user-check-validate--actions-close-checkmark-check-geometric-human-person-single-success-up-user.svg" }, { title: "Database Management", desc: "Efficient and scalable database solutions with backup, recovery, and optimization services.", image1: "/database-check--raid-storage-code-disk-programming-database-array-hard-disc-check-approve.svg" }, { title: "API Integration", desc: "Seamless communication between different software systems through comprehensive API integration and development.", image1: "/code-monitor-1--code-tags-angle-bracket-monitor.svg" }, { title: "File Upload and Management", desc: "Allow users to upload, manage, and share files securely within the application.", image1: "/shift--key-shift-up-arrow-keyboard.svg" }, { title: "Real-Time Data Processing", desc: "Real-time data updates and notifications to keep users informed and engaged.", image1: "/circle-clock--clock-loading-measure-time-circle.svg" }, { title: "E-commerce Functionality", desc: "Integrated shopping cart, payment gateways, and product management for a complete e-commerce solution.", image1: "/shopping-cart-1--shopping-cart-checkout.svg" }, { title: "Analytics and Reporting", desc: "Insightful analytics and reporting tools to track user behaviour and application performance.", image1: "/multiple-file-2--double-common-file.svg" }, { title: "Custom Dashboards", desc: "Create personalized dashboards that offer a comprehensive overview of key metrics and data points.", image1: "/graph--analytics-business-product-graph-data-chart-analysis.svg" }]} />
        </div>
      </div>
      <Footer page={"static"} />
    </div>
  );
};

export default VCTFullStack;
