import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const StaticHero = ({ className = "" }) => {
  return (
    <div
      className={`self-stretch flex w-full flex-row items-start justify-start py-0 px-0.5 box-border max-w-full text-left text-51xl-5 z-[1] text-white ${className}`}
    >
      <div className="flex-1 w-[1400px] mq575:mx-0 flex flex-row mx-12 mq900:mx-4 items-center justify-between gap-[20px] mq1725:flex-wrap mt-12 mqAllMobile:mt-0">
        <b className=" w-[685px] mq825: inline-block shrink-0 min-w-[685px] mq1024:min-w-[300px] mq1024:w-fit max-w-full mq450:text-23xl mq900:text-37xl mq1725:flex-1 font-montserrat mqAllMobile:!text-4xl">
          Custom Static Websites Tailored to Your Vision
        </b>
        <div className="mq750:max-w-[500px] mq575:max-w-[400px] mq450:max-w-[325px] min-w-[695px] max-w-full mq1024:min-w-full flex flex-col items-start justify-start gap-[40px] text-4xl text-gray-400 font-poppins mqAllMobile:!gap-[15px] mq1725:flex-1 w-[695px]">
          <h3 className="m-0 self-stretch relative text-inherit font-bold font-montserrat z-[1] mqAllMobile:!text-lg">
            Beautifully Designed, Blazing-Fast, and Highly Secure
          </h3>
          <Link to="/pricing" className="w-[222px] no-underline rounded-3xs bg-darkorchid-200 flex flex-row items-start justify-start py-[11px] pr-0.5 box-border whitespace-nowrap z-[1] text-center text-lgi mqAllMobile:text-mid mqAllMobile:w-fit mqAllMobile:px-4 mqAllMobile:py-2 text-white">
            <div className="flex-1 relative z-[1]">Get Started Today</div>
          </Link>
          <p className="m-0 self-stretch relative text-base text-justify z-[1] mqAllMobile:!text-smi">
            Your website is a reflection of your brand. At Vantage Cybertech, we
            create custom static websites that perfectly align with your
            business goals and aesthetic preferences. Enjoy the benefits of fast
            load times, enhanced security, and a unique design that sets you
            apart from the competition.
          </p>
        </div>
      </div>
    </div>
  );
};

StaticHero.propTypes = {
  className: PropTypes.string,
};

export default StaticHero;


{/**

import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const StaticHero = ({ className = "" }) => {
  return (
    <div
      className={`self-stretch flex w-full flex-row items-start justify-start py-0 px-0.5 box-border max-w-full text-left text-51xl-5 text-white ${className}`}
    >
      <div className="flex-1 flex flex-col md:flex-row mx-4 md:mx-12 items-center justify-between gap-[20px] mt-12">
        <b className="w-full md:w-auto md:min-w-[300px] max-w-full relative inline-block shrink-0 z-[1] text-23xl md:text-37xl lg:text-51xl-5 font-montserrat text-center md:text-left">
          Custom Static Websites Tailored to Your Vision
        </b>
        <div className="w-full md:w-auto min-w-full md:min-w-[773px] max-w-full flex flex-col items-start justify-start gap-[20px] md:gap-[40px] text-lg md:text-4xl text-gray-400 font-poppins">
          <h3 className="m-0 self-stretch relative text-inherit font-bold font-montserrat z-[1] text-center md:text-left">
            Beautifully Designed, Blazing-Fast, and Highly Secure
          </h3>
          <Link to="/pricing" className="w-full md:w-auto no-underline rounded-3xs bg-darkorchid-200 flex flex-row items-center justify-center py-[11px] pr-0.5 box-border whitespace-nowrap z-[1] text-center text-lgi text-white">
            <div className="flex-1 relative z-[1]">Get Started Today</div>
          </Link>
          <p className="m-0 self-stretch relative text-base text-justify z-[1]">
            Your website is a reflection of your brand. At Vantage Cybertech, we
            create custom static websites that perfectly align with your
            business goals and aesthetic preferences. Enjoy the benefits of fast
            load times, enhanced security, and a unique design that sets you
            apart from the competition.
          </p>
        </div>
      </div>
    </div>
  );
};

StaticHero.propTypes = {
  className: PropTypes.string,
};

export default StaticHero;

 */}