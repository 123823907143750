import React, { useEffect, useState } from "react";
import { throttle, debounce } from "lodash";

const Profiles = [
    {
        name: "Software Development",
        People: [
            {
                name: "Saksham V.",
                role: "Full Stack Software Engineer",
                bio: "Full-stack software engineer recruited by VCT, where I am honing my skills in both front-end and back-end development. With expertise in React.js, Node.js, Next.js, and AWS, I’m passionate about building scalable, high-performance applications. I enjoy solving complex problems and delivering solutions that enhance user experiences while continuously learning and adapting to new technologies in the fast-paced tech landscape.",
                quote: '"I’ve had the opportunity to work on a wide range of challenging projects at VCT. Being part of such a dynamic team has allowed me to continuously learn and grow as a developer while contributing to impactful solutions. The experience gained here has been invaluable, supported by regular guidance and encouragement."',
                skills: ["Node.js", "React.js", "Next.js", "Laravel", "AWS", "TypeScript"],
                image: "/Saksham.jpeg"
            },
            {
                name: "Kartavya S.",
                role: "Full Stack Software Engineer",
                bio: "I am a full-stack software engineer specializing in building scalable, dynamic web applications using technologies like React.js, MongoDB, and Node.js. With a passion for both front-end and back-end development, I focus on delivering efficient, high-quality solutions that drive business growth and enhance user experiences.",
                quote: '"At VCT, I’ve had the opportunity to excel across my full tech stack, have impactful results from day one and gain experience with the top companies in my industry."',
                image: "/Kartavya.png",
                skills: []
            }
        ]
    }
]

const CandidateProfile = ({ ClassName }) => {
    const [selectedProfile, setSelectedProfile] = useState(0);
    const [selectedProfilePeople, setSelectedProfilePeople] = useState(0);
    const [fadeClass, setFadeClass] = useState("fade-in");

    const AllProfiles = Profiles.map(profile => profile.name);
    const profilePeoples = Profiles[selectedProfile].People.map(people => people.name);
    const selectedPersonForBio = Profiles[selectedProfile].People[selectedProfilePeople];

    const defaultChangeTimer = 20000 // 20 sec
    let intervalId;

    const fetchAllCandidateImageFromProfiles = () => {
        return Profiles.flatMap(profile =>
            profile.People.map(person => person.image)
        );
    };
    const allCandidateImages = fetchAllCandidateImageFromProfiles();

    const resetInterval = () => {
        clearInterval(intervalId);
        intervalId = setInterval(() => {
            changeProfileAutomatically();
        }, defaultChangeTimer);
    };

    const handleProfileClick = throttle((index) => {
        setFadeClass("fade-out");
        setTimeout(() => {
            setSelectedProfile(index);
            setFadeClass("fade-in");
        }, 500);
    }, 1000);

    const handlePeopleClick = debounce((index) => {
        setFadeClass("fade-out");
        setTimeout(() => {
            setSelectedProfilePeople(index);
            setFadeClass("fade-in");
        }, 500);
    }, 500);


    const changeProfileAutomatically = () => {
        setFadeClass("fade-out");
        setTimeout(() => {
            setSelectedProfilePeople((prev) => (prev + 1) % Profiles[selectedProfile].People.length);
            setFadeClass("fade-in");
        }, 500);
    };

    useEffect(() => {
        resetInterval();
        return () => clearInterval(intervalId);
    }, [selectedProfile, selectedProfilePeople]);

    return (
        <div
            className={`relative overflow-hidden justify-start py-10 px-5 max-w-[1350px] mt-24 mqAllMobile:mt-12 mqAllDesktop:mx-auto mqAll:mx-auto mqAllMobile:!mx-[10px] mq1450:mx-[20px] flex flex-col md:flex-row items-center text-white text-left font-sans bg-gradient-to-br from-[#1c013d] to-[#2e0249] rounded-lg gap-6 mqAllMobile:gap-2 mq575:py-5 ${ClassName}`}
        >
            <div className="flex flex-col gap-1 mq575:gap-0 w-full md:w-2/3">
                <h2 className="text-3xl font-bold font-montserrat mqAllMobile:my-1">Who we've recently hired.</h2>
                <p className="text-lg text-gray-600 font-poppins mqAllMobile:text-smi mqAllMobile:my-2">
                    We specialise in tech, however we have expanded to other industries as part of a tailored package for our clients.
                </p>
                <div className="flex flex-wrap !gap-2 my-2 font-montserrat mqAllMobile:m-1">
                    {AllProfiles.map(
                        (profile, index) => (
                            <button
                                key={index}
                                className={"px-4 py-2 cursor-pointer text-sm bg-[#ffffff1a] hover:bg-[#ffffff33] text-gray-200 border border-gray-500 rounded-full shadow-sm transition-all " + (index == 0 && "bg-[#ffffff54]")} onClick={() => handleProfileClick(index)}
                            >
                                {profile}
                            </button>
                        )
                    )}
                </div>
                <div className="flex flex-wrap !gap-3 mq575:!gap-1 my-2 font-poppins mqAllMobile:m-1">
                    {profilePeoples.map((Employee, index) => (
                        <span
                            key={index}
                            className="px-2 cursor-pointer py-1 text-sm border border-blue-400 rounded-full hover:bg-blue-400 hover:text-white bg-opacity-20 bg-blue-900 text-blue-400 transition-all text-pink-400 bg-pink-900 border-pink-400" onClick={() => handlePeopleClick(index)}
                        >
                            {Employee}
                        </span>
                    ))}
                </div>
            </div>
            <div className={"flex flex-col gap-6 mq575:gap-0 w-full md:w-1/3 bg-[#ffffff1a] backdrop-blur-md p-6 mq575:p-3 rounded-lg shadow-lg font-poppins " + fadeClass}>
                <div className="flex items-center gap-4">
                    <img
                        src={selectedPersonForBio.image}
                        alt="Profile"
                        className="w-20 h-20 rounded-full border border-gray-500"
                    />
                    <div>
                        <h3 className="text-xl font-semibold font-montserrat mqAllMobile:m-2">{selectedPersonForBio.name}</h3>
                        <p className="text-gray-400 font-montserrat font-bold mqAllMobile:m-2">{selectedPersonForBio.role}</p>
                    </div>
                </div>
                <p className="text-sm text-gray-300 leading-relaxed mqAllMobile:text-smi mt-1">
                    {selectedPersonForBio.bio}
                </p>
                {selectedPersonForBio.quote && <p className="mqAllMobile:text-smi mt-1">
                    {selectedPersonForBio.quote}
                </p>}
                {selectedPersonForBio.skills && <div className="flex flex-wrap gap-3 mq575:gap-1">
                    {selectedPersonForBio.skills.map(
                        (skill, index) => (
                            <span
                                key={index}
                                className="px-3 py-1 text-xs mqAllMobile:text-[15px] text-pink-400 bg-pink-900 bg-opacity-20 border border-pink-400 rounded-full hover:bg-pink-400 hover:text-white transition-all"
                            >
                                {skill}
                            </span>
                        )
                    )}
                </div>}
                <div className="flex justify-between items-center mt-4">
                    <button className="bg-[#3a00b6] text-white px-4 py-2 mq575:p-2 rounded-lg shadow-lg hover:bg-[#5200ff] mqAllMobile:text-smi transition-all font-montserrat">
                        Meet {selectedPersonForBio.name}
                    </button>
                    <div className="flex mq450:ml-4 items-center gap-2 mq575:gap-1">
                        {allCandidateImages.slice(0, 3).map((_, index) => (
                            <img
                                key={index}
                                src={_}
                                alt={`Candidate ${index + 1}`}
                                className="w-8 h-8 rounded-full border border-gray-500"
                            />
                        ))}
                        <span className="text-gray-400 text-sm text-right mqAllMobile:text-smi">{allCandidateImages.length > 3 ? `+${allCandidateImages.length - 3} more Candidates` : " & more Candidates"}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CandidateProfile;
