import PropTypes from "prop-types";

const PackageFeatures = ({ className = "", data }) => {
  const MyTab = ({ title, desc, ImageComponent }) => <div className="flex-1 self-stretch max-h-full">
    <div className="flex-1 shadow-[0px_6.7px_11.16px_rgba(0,_0,_0,_0.03)] rounded-[8.93px] bg-gainsboro-400 box-border flex flex-col items-end justify-start py-10 px-[34px] mqAllMobile:px-[10px] gap-[42.4px] min-w-[350px] border-[1.1px] border-solid border-light-100 mqAllMobile:gap-[21px] mqAllMobile:py-[26px] mqAllMobile:box-border mq900:min-w-full min-h-full">
      <div className="self-stretch flex flex-row items-start justify-center py-0 pr-0 pl-[3px]">
        <div className="w-[100px] flex flex-row items-center justify-center pt-[2.1px] px-0 pb-0 box-border z-[1]">
          <div className="h-[69px] flex relative justify-center items-center">
            <ImageComponent />
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[32px] mqAllMobile:gap-[16px]">
        <div className="self-stretch flex flex-row items-start justify-center py-0 pr-[27px] pl-5">
          <div className="relative tracking-[0.22px] leading-[27px] z-[1] mqAllMobile:text-lg mqAllMobile:leading-[21px] font-montserrat text-5xl">
            {title}
          </div>
        </div>
        <p className="m-0 self-stretch relative text-base leading-[22px] text-gray-400 mqAllMobile:leading-snug z-[1] font-poppins mqAllMobile:text-smi">
          {desc}
        </p>
      </div>
    </div>
  </div>

  return (
    <div
      className={`self-center mqAllMobile:self-auto flex flex-row items-start justify-start pt-0 px-[18px] mq550:px-[15px] mq450:px-[10px] pb-[29px] box-border max-w-full text-center text-xl text-light-100 font-poppins ${className}`}
    >
      <div className="flex-1 flex flex-row flex-wrap items-start justify-center gap-[33px] max-w-[1400px] z-[1] mq900:gap-[16px] mqAllMobile:grid mqAllMobile:grid-cols-2 mq350:grid-cols-1">
        {data.map((item, index) => (
          <MyTab
            key={index}
            title={item.title}
            desc={item.desc}
            ImageComponent={() => (
              <div className="flex w-full h-[69px]">
                <img
                  className="h-[57.8px] self-center z-[1]"
                  alt=""
                  src={item.image1}
                />
              </div>
            )}
          />
        ))}
      </div>
    </div>
  );
};

PackageFeatures.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      desc: PropTypes.string.isRequired,
      image1: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default PackageFeatures;
