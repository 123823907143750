import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useBrowser } from "../pages/Provider/BrowserContext";

const Header = ({ className = "" }) => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false); // State to track scroll position
  const navigate = useNavigate();
  const w = useBrowser().windowDimensions.width;

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50); // Change to `true` when scrolled more than 50px
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const menuItems = [
    { title: 'Home', link: '/' },
    { title: 'Portfolio', link: '/portfolio' },
    {
      title: 'Software Development',
      link: '#',
      subItems: [
        { title: 'Full-Stack Web Applications', link: "/fullstack" },
        { title: 'Static Websites', link: "/static" },
        { title: 'Bespoke Software', link: "/bespoke" }
      ]
    },
    { title: 'Offshore Scaling', link: "/offshore" },
    { title: 'FAQ', link: "/faq" },
    { title: 'Contact', link: '/contact' },
  ];

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <header
        className={`fixed top-0 left-0 w-full z-[1000] transition-all duration-300 ${isScrolled
          ? "bg-[rgba(0,0,0,0.7)] backdrop-blur-md shadow-md"
          : "bg-transparent"
          } ${className}`}
      >
        <div className="self-stretch flex flex-row items-center justify-center py-3 px-5 box-border max-w-full text-center text-lg text-white font-adamina mqAllMobile:py-0 mqAllMobile:px-1">
          <div className="w-[1400px] flex flex-row items-center justify-between gap-[20px] max-w-full mq1250:w-[1319px]">
            {/* Logo */}
            <div
              className="h-[80px] w-[161px] mqAllMobile:h-[80px] mqAllMobile:w-[111px] flex flex-col items-start justify-start mqAllMobile:py-[15px] px-0 pb-0 box-border cursor-pointer"
              onClick={() => navigate('/')}
            >
              <img
                className={`self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover`}
                loading="lazy"
                alt=""
                src={"/image-7removebgpreview-1@2x.png"}
              />
            </div>

            {/* Menu Items */}
            <div className="w-full justify-end mq825:w-[360px] mqAll:w-[800px] flex flex-row items-start gap-[8px] max-w-full mq1024:hidden font-montserrat">
              {menuItems.map((item, index) => (
                <div
                  className="flex flex-col items-center h-[80px] justify-center pt-0.5 px-0 pb-0 relative"
                  key={index}
                  onMouseEnter={() => setHoveredItem(index)}
                  onMouseLeave={() => setHoveredItem(null)}
                >
                  <Link
                    to={item.link}
                    className={`[text-decoration:none] relative text-[inherit] text-center min-w-max py-4 flex items-center justify-center shrink-0 cursor-pointer m-3 mq825:m-1 hover:text-gray-100 ${hoveredItem === index ? "text-gray-400" : ""
                      }`}
                  >
                    {item.title}
                  </Link>
                  {item.subItems && (
                    <div
                      className={`absolute w-[300px] right-0 bg-neutral-800/80 top-full z-[200] p-6 [backdrop-filter:blur(11.6px)] box-border border-[1px] border-solid border-gray-700 shadow-lg rounded-lg transition-all duration-300 ${hoveredItem === index ? 'block' : 'hidden'}`}
                    >
                      <div className="flex flex-col">
                        {item.subItems.map((subItem, subIndex) => (
                          <Link
                            to={subItem.link}
                            key={subIndex}
                            className="block p-2 text-gray-50 hover:bg-gray-100 rounded-md no-underline"
                          >
                            {subItem.title}
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>

            {/* Burger Menu for Mobile */}
            <div className="hidden flex-row items-start justify-start max-w-full mq1024:flex mqAllMobile:py-[15px]">
              <div className="flex-1 flex flex-col items-start justify-start pt-0.5 px-0 pb-0">
                <button
                  onClick={toggleModal}
                  className="[text-decoration:none] self-auto bg-transparent h-auto mqAllMobile:h-auto relative text-[inherit] flex items-center justify-center shrink-0"
                >
                  <img
                    className="w-[50px] mqAllMobile:w-[40px] bg-white rounded-t-lg object-fit"
                    alt=""
                    src="/burger-menu-svgrepo-com.svg"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Modal for Mobile Menu */}
      </header>
      <div className="w-full mt-[106px] mqAllMobile:!mt-[90px]">
        {" "}
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 z-[2000] flex flex-col justify-center overflow-auto bg-neutral-800/80 px-4 py-2 text-neutral-100 backdrop-blur visible opacity-100 transition-opacity duration-200">
          <nav className="grid grid-cols-1 text-xl leading-9 sm:text-2xl sm:leading-10 font-montserrat">
            {/* <div className="absolute flex justify-end w-full">
            </div> */}
            <button
              onClick={toggleModal}
              className="mb-4 h-7 w-16 bg-neutral-950/80 border border-white hover:bg-gray-200 rounded-full flex items-center justify-center self-end"
              aria-hidden="true"
            >
              <svg
                className="h-6 w-6 text-white hover:text-black"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="3.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
            {menuItems.map((item, index) => (
              <div key={index} className="border-t border-neutral-400 py-4">
                <Link
                  to={item.link}
                  className="block font-normal no-underline text-white text-7xl"
                  onClick={toggleModal}
                >
                  {item.title}
                </Link>
                {item.subItems && (
                  <div className="flex flex-col mt-2">
                    {item.subItems.map((subItem, subIndex) => (
                      <Link
                        to={subItem.link}
                        key={subIndex}
                        className="text-lg text-gray-200 hover:text-gray-200 block my-1 no-underline"
                        onClick={toggleModal}
                      >
                        {subItem.title}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </nav>
        </div>
      )}
    </>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
