import React from 'react';
import { PlanCard } from './Plans';
import { useBrowser } from '../pages/Provider/BrowserContext';

function HiringComponent() {
  const plans = [
    {
      title: "Hiring locally is stressful and expensive.",
      features: [
        { title: "Extreme costs of hiring local talent.", checked: false },
        { title: "Costs of setting up a recruitment team.", checked: false },
        { title: "Time of screening and shortlisting the huge volume of applicants.", checked: false },
        { title: "Low retention rates.", checked: false },
      ],
    },
    {
      title: "We handle the entire end-to-end process.",
      features: [
        { title: "5-stage vetting process, final interview with you.", checked: true },
        { title: "Provided office space to work with teammates.", checked: true },
        { title: "Legal, HR and payroll all fully managed by us.", checked: true },
        { title: "Top talent with minimum expenses.", checked: true },
      ],
    },
  ];

  const w = useBrowser().windowDimensions.width;

  return (
    <div className="w-full component-container mb-[100px] mqAllMobile:!mb-11">
      <div
        className="component p-8 mqAllMobile:!p-1 mqAllMobile:mx-1 mqAllMobile:!gap-1 text-white font-poppins w-[calc(100%_-_0.5rem)] max-w-[1156px] [backdrop-filter:blur(10px)] [background:linear-gradient(95.76deg,_rgba(255,_255,_255,_0.04),_rgba(255,_255,_255,_0.03)_26.43%,_rgba(255,_255,_255,_0.1)_77.28%,_rgba(255,_255,_255,_0.1))] box-border overflow-hidden flex flex-col items-center justify-start mx-auto gap-[30px] border-[1px] border-solid border-gray-300 mq825:gap-[15px] mq1250:gap-[20px] mq450:pt-[10px] mq450:box-border px-4"
      >
        <h2 className="text-3xl mqAllMobile:text-xl font-bold text-center mqAllMobile:my-1">Why take advantage of offshore talent?</h2>
        <div className="w-full flex-wrap overflow-hidden items-start justify-evenly box-border gap-[10px] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 px-[10px] lg:px-[25px] mqAllMobile:!px-0 mq825:grid-cols-1 mq1450:grid-cols-2 mqAllMobile:!gap-[5px]">
          {plans.map((plan, index) => (
            <PlanCard
              key={index}
              index={index + 2}
              title={plan.title}
              price={plan.price}
              features={plan.features}
            />
          ))}
        </div>
        <button className="rounded-[55.08px] text-[18px] [background:linear-gradient(132.03deg,_#c471ed,_#a044ff)] overflow-hidden text-white font-bold py-2 px-4 mqAllMobile:mb-2">Get Started</button>
      </div>
    </div>
  );
}

export default HiringComponent;