import StaticHero from "../components/StaticHero";
import OurProcess from "../components/OurProcess";
import { Footer } from "../components/FooterTop";
import Header from "../components/Header";
import OurExpertise from "../components/OurExpertise";
import GetInTouch from '../components/GetInTouch'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";

export const ourProcessContent = [
  {
    title: "Pick a Template or Share Inspiration",
    desc: `Start by choosing from our collection of templates or share your
           inspiration with us. We'll help you select a design that suits
           your needs and vision.`,
    image: "/rectangle-121@2x.png",
    alignment: "left",
  },
  {
    title: "Detailed Wireframes",
    desc: `We create detailed wireframes to give you a clear picture of the website layout and functionality. Your feedback is crucial at this stage to ensure we are aligned with your vision.`,
    image: "/rectangle-13@2x.png",
    alignment: "right",
  },
  {
    title: "Your Approval & Satisfaction",
    desc: `After reviewing the wireframes, you give us the go-ahead to proceed. We ensure that you are completely satisfied with the planned structure before we start development.`,
    image: "/rectangle-14@2x.png",
    alignment: "left",
  },
  {
    title: "Development, Hosting and Domain Setup",
    desc: `Our team develops the website, ensuring it meets the highest standards. We also take care of hosting and domain setup, providing you with a fully functional and live website.`,
    image: "/rectangle-15@2x.png",
    alignment: "right",
  },
  {
    title: "Future Support",
    desc: `Launching your website is just the beginning. We understand that maintaining and optimising your site is crucial for its success. That's why we offer comprehensive future support to ensure your website continues to perform at its best.`,
    image: "/rectangle-16@2x.png",
    alignment: "left",
  },
];

const VCTStaticPage = () => {

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <div className="w-full relative min-h-screen bg-black overflow-hidden flex flex-col items-start justify-start leading-[normal] tracking-[normal]">
      <div className="w-[2487px] h-full absolute !m-[0] left-[-291px] flex items-center justify-center z-[0]">
        <img
          className="w-full h-full object-contain absolute left-[0px] top-[-86px] [transform:scale(1.022)]"
          alt=""
          src="/element.svg"
        />
      </div>
      <Header />
      <div className="self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[9px] box-border max-w-full">
        <div className="flex flex-col items-start justify-start gap-[50px] max-w-full mq900:gap-[25px]">
          <StaticHero />
        </div>
      </div>
      <div className="self-center w-[1400px] flex flex-row items-start justify-start pt-0 px-0 pb-[148px] box-border max-w-full font-montserrat mq900:pb-2 mq900:box-border mq1325:pb-[62px] mq1325:box-border">
        <OurProcess ourProcessContent={ourProcessContent} title={"Our Process"} />
      </div>
      <div className="w-full flex flex-row items-start justify-center pt-0 px-5 pb-[132px] box-border max-w-full text-left text-5xl mq900:pb-14 mq900:box-border mq1325:pb-[86px] mq1325:box-border mqAllMobile:!pb-[20px]">
        <div data-aos={"fade-up"} className="w-[1400px] flex flex-row items-end justify-start gap-[64px] max-w-full mq900:gap-[32px] mq1325:flex-wrap mqAllMobile:!gap-[16px]">
          <div className="flex-1 flex flex-col items-start justify-end pt-0 px-0 pb-px box-border min-w-[483px] max-w-full mq900:min-w-full">
            <div className="self-stretch flex flex-row items-start justify-start p-2.5 box-border shrink-0 [debug_commit:bf4bc93] max-w-full z-[1]">
              <img
                className="h-[889px] flex-1 relative mq900:h-[400px] mqAllMobile:!h-[300px] rounded-[40px] max-w-full overflow-hidden object-cover"
                alt=""
                src="/rectangle-12-1@2x.png"
              />
            </div>
          </div>
          <OurExpertise />
        </div>
      </div>
      <GetInTouch />
      <Footer page={"static"} />
    </div>
  );
};

export default VCTStaticPage;
