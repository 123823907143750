import Header from "../components/Header";
import FrameComponent2 from "../components/FrameComponent2";
import InfoColumns from "../components/InfoColumns";
import FrameComponent1 from "../components/FrameComponent1";
import NeedAWebsite from "../components/NeedAWebsite";
import FooterTop from "../components/FooterTop";
import Testimonials from "../components/Testimonials";
import CandidateProfile from "../components/CandidateProfile";
import HiringComponent from "../components/HiringComponent";
import CostCalculatorComponent from "../components/CostCalculatorComponent";
import MarqueeComponent from "../components/MarqueeComponent";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";

const gridColumnsData = [
  {
    title: "Scale Your Business from Local to Global",
    description: "Your website is the first step towards scaling your business. It extends your reach from a local audience to a global market. With an online presence, your business operates 24/7, reaching customers across the world without geographical limitations. This global accessibility opens up unprecedented growth opportunities."
  },
  {
    title: "Build Brand Image and Reputation",
    description: "A premium-looking website builds your brand image and establishes trust with your audience. In an age where first impressions matter, a well-designed website conveys professionalism and reliability, making your business more appealing to potential customers. It’s a cornerstone of your brand’s online identity."
  },
  {
    title: "Gain a Competitive Edge",
    description: "Your website could be the reason a customer chooses your service over a competitor’s. A compelling and user-friendly website highlights your unique selling points, showcases testimonials, and effectively convinces visitors to choose you. It gives you a significant edge in a competitive market."
  },
  {
    title: "Open New Avenues for Engagement",
    description: "Your website can enable new engagement opportunities, such as starting a blog or a mailing list. Users can sign up directly on your site, opening up new marketing channels and building a community around your brand. This not only enhances customer engagement but also creates a lasting digital footprint."
  },
  {
    title: "Enhance Customer Management",
    description: "Incorporate a customer management system on your website to handle communications seamlessly. This can drastically reduce the need for phone calls or emails, freeing up your time to focus on other critical aspects of your business. An efficient customer management system improves the user experience and operational efficiency."
  },
  {
    title: "Reduce Friction in Client Communication", size: 'text-f27',
    description: "When detailed information is readily available on your website, potential clients can learn about your services and make informed decisions without the need for extensive back-and-forth communication. This reduces friction and makes it easier for clients to engage with you, leading to quicker and more efficient deal closures."
  },
  {
    title: "Streamline Scheduling and Bookings", size: 'text-f27',
    description: "Integrate a scheduling or booking system directly on your website to automate and streamline your business operations. This feature eliminates the need for manual management and allows customers to book appointments or services at their convenience, increasing customer satisfaction and operational efficiency."
  },
];

const VCTLandingPage = () => {

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <div className="w-full relative min-h-screen bg-black overflow-hidden flex flex-col items-start justify-start leading-[normal] tracking-[normal]">
      <div className="w-[2487px] h-[7910px] absolute !m-[0] bottom-[-3620px] left-[-291px] flex items-center justify-center z-[0]">
        <img
          className="w-full h-full object-contain absolute left-[0px] top-[-86px] [transform:scale(1.022)]"
          alt=""
          src="/element.svg"
        />
      </div>
      <Header />
      <FrameComponent2 />
      <div className="self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[100px] box-border max-w-full mqAllMobile:!pb-11 mq825:pb-[59px] mq825:box-border mq1250:pb-[91px] mq1250:box-border mqAllMobile:!px-3" data-aos={"fade-up"}>
        <div className="w-[1720px] flex flex-col items-start justify-start gap-[92px] max-w-full mq825:gap-[46px] mq450:gap-[23px]">
          <InfoColumns className="max-w-[1400px] !self-center" />
          <FrameComponent1 />
        </div>
      </div>
      {/* <div data-aos={"fade-up"} className="w-full">
        <HiringComponent />
      </div> */}
      <CostCalculatorComponent />
      <NeedAWebsite className="z-[100]" gridColumnsData={gridColumnsData} />
      <Testimonials ClassName="z-[100]" />
      <MarqueeComponent />
      <div data-aos={"fade-up"} className="w-full">
        <CandidateProfile ClassName="z-[100]" />
      </div>
      <FooterTop />
    </div>
  );
};

export default VCTLandingPage;
