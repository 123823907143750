import FAQHeadComponent from "../components/FAQHeadComponent";
import Header from "../components/Header";
import { Footer } from "../components/FooterTop";
import FAQContentComponent from "../components/FAQContentComponent";

const FAQVCTPAGE = () => {
  return (
    <div className="w-full relative min-h-screen bg-black overflow-hidden flex flex-col items-start justify-start leading-[normal] tracking-[normal]">
      <div className="w-[2487px] h-full absolute !m-[0] left-[-291px] flex items-center justify-center z-[0]">
        <img
          className="w-full h-full object-contain absolute left-[0px] top-[-86px] [transform:scale(1.022)]"
          alt=""
          src="/element.svg"
        />
      </div>
      <Header />
      <FAQHeadComponent />
      <FAQContentComponent />
      <Footer page={"static"} />
    </div>
  );
};

export default FAQVCTPAGE;
