import ContactCard from "../components/ContactCard";
import Header from "../components/Header";
import { Footer } from "../components/FooterTop";
import { Link } from "react-router-dom"
import GetInTouch from "../components/GetInTouch";

const ContactUsPage = () => {
    return (
        <div className="w-full min-h-screen relative bg-black overflow-hidden flex flex-col items-start justify-start leading-[normal] tracking-[normal]">
            <div className="w-[2487px] h-[7910px] absolute !m-[0] bottom-[-3620px] left-[-291px] flex items-center justify-center z-[0]">
                <img
                    className="w-full h-full object-contain absolute left-[0px] top-[-86px] [transform:scale(1.022)]"
                    alt=""
                    src="/element.svg"
                />
            </div>
            <Header />
            <div className="self-stretch flex flex-col items-end justify-start gap-[20px] max-w-full">
                <div className="self-stretch overflow-hidden flex flex-col items-center justify-between py-[78px] px-5 box-border min-h-[406px] mqAllMobile:min-h-fit max-w-full z-[1] mqAllMobile:pt-[51px] mqAllMobile:pb-[51px] mqAllMobile:box-border ">
                    <div className="w-[748px] flex flex-col items-center justify-start gap-[15px] max-w-full">
                        <Link to="/" className="mqAllMobile:hidden">
                            <img
                                className="w-[197px] h-[116px] relative object-cover cursor-pointer"
                                loading="lazy"
                                alt=""
                                src="/image-7removebgpreview-1@2x.png"
                            />
                        </Link>
                        <div className="self-stretch flex flex-col items-center justify-start gap-[35px] max-w-full mq750:gap-[17px]">
                            <b className="relative leading-[40px] font-montserrat text-white text-32xl font-bold inline-block max-w-full mqAllMobile:leading-[24px] mq1050:text-[45px] mq1050:leading-[32px] mqAllMobile:!text-4xl">
                                Contact Us
                            </b>
                            <p className="m-0 self-stretch h-11 relative text-base leading-[22px] font-poppins text-gray-400 text-center flex items-center justify-center shrink-0 mqAllMobile:h-fit mqAllMobile:!text-mid">
                                The best way to reach us is by dropping us an email or a message using the form below.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="mx-auto mb-12">
                    <a className="cursor-pointer mb-5 [border:none] py-2.5 px-[15px] bg-darkorchid-200 rounded-3xs flex flex-row items-start justify-start z-[1] hover:bg-darkorchid-300 no-underline" href="https://calendly.com/shawak-vantagecybertech/30min?month=2024-12" target="_blank">
                        <div className="relative text-xl font-poppins text-white text-center inline-block min-w-[71px] mq450:text-base" >
                            Book a Call
                        </div>
                    </a>
                    <div className="text-white text-center text-3xl font-montserrat font-[400] mqAllMobile:text-mid">OR</div>
                </div>
                <GetInTouch className="mt-[-25px]" removeGetInTouch={false} />
            </div>
            <Footer />
        </div>
    );
};

export default ContactUsPage;
