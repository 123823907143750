import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import VCTLandingPage from "./pages/VCTLandingPage";
import ContactUsPage from "./pages/VCTContactPage";
import NotFoundPage from "./pages/NotFoundPage.js";
import VCTStaticPage from "./pages/VCTStaticPage.js";
import FAQVCTPAGE from "./pages/FAQVCTPAGE.js";
import VCTFullStack from "./pages/VCTFullStack.js";
import VCTBespoke from "./pages/VCTBespoke.js";
import PricingPage from "./pages/PricingPage.js";
import Portfolio from "./pages/PortFolioPage.js";
import VCTOffshore from "./pages/VCTOffshore.js";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "Vantage Cybertech: The Offshore Specialists";
        metaDescription = "Enhance your business's potential with our services. Our expertise lies in recruiting the top 1% talent offshore for small businesses.";
        break;
      case "/contact":
        title = "Contact Us - VCT";
        metaDescription = "Get in touch with us through our contact page.";
        break;
      case "/static":
        title = "Static Web - VCT";
        metaDescription = "Explore our static web design services, ideal for businesses looking for a simple, yet professional online presence.";
        break;
      case "/fullstack":
        title = "FullStack Web - VCT";
        metaDescription = "Discover our full-stack web development services, providing comprehensive solutions from frontend to backend.";
        break;
      case "/faq":
        title = "FAQ - VCT";
        metaDescription = "Find answers to frequently asked questions about our web development services.";
        break;
      case "/bespoke":
        title = "Bespoke Web - VCT";
        metaDescription = "Learn about our bespoke web design services, tailored to meet your unique business needs.";
        break;
      case "/offshore":
        title = "Offshore Scaling - VCT'";
        metaDescription = "The top 1% of global talent is now within your budget, delivering exceptional quality at a fraction of the cost.";
        break;
      case "/pricing":
        title = "Pricing - VCT";
        metaDescription = "Check out our pricing plans for various web development services to suit your budget.";
        break;
      case "/portfolio":
        title = "Portfolio - VCT";
        metaDescription = "Check out our works. We will be glad to see you with us.";
        break;
      default:
        title = "404 - Page Not Found";
        metaDescription = "The page you are looking for does not exist.";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<VCTLandingPage />} />
      <Route path="/contact" element={<ContactUsPage />} />
      <Route path="*" element={<NotFoundPage />} />
      <Route path="/static" element={<VCTStaticPage />} />
      <Route path="/fullstack" element={<VCTFullStack />} />
      <Route path="/faq" element={<FAQVCTPAGE />} />
      <Route path="/bespoke" element={<VCTBespoke />} />
      <Route path="/offshore" element={<VCTOffshore />} />
      <Route path="/pricing" element={<PricingPage />} />
      <Route path="/portfolio" element={<Portfolio />} />
    </Routes>
  );
}

export default App;
