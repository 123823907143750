import React, { useEffect, useState } from 'react';

const theScores = [
  {
    country: "USA",
    flag: "https://static.vecteezy.com/system/resources/previews/013/142/902/large_2x/american-flag-thanksgiving-usa-flat-color-icon-icon-banner-template-free-vector.jpg",
    BTFScoreInPounds: 76,
    BTFOptionalScoreInPounds: 100,
    SScoreInPounds: 100,
    SOptionalScoreInPounds: 132,
    TScoreInPounds: 178,
    TOptionalScoreInPounds: 233,
    savings: 148
  },
  {
    country: "UK",
    flag: "https://static.vecteezy.com/system/resources/previews/009/767/148/large_2x/united-kingdom-flag-flag-of-uk-free-vector.jpg",
    BTFScoreInPounds: 30,
    SScoreInPounds: 70,
    TScoreInPounds: 100,
    savings: 70
  },
  {
    country: "VCT",
    flag: "/imgLogo.png",
    BTFScoreInPounds: 5,
    SScoreInPounds: 25,
    TScoreInPounds: 30,
    savings: 0
  }
];

function CostCalculatorComponent() {
  function findMaxScores(scores) {
    const maxScoresFound = {
      MaxBTFScoreInPounds: Math.max(...scores.map(score => score.BTFScoreInPounds)),
      MaxSScoreInPounds: Math.max(...scores.map(score => score.SScoreInPounds)),
      MaxTScoreInPounds: Math.max(...scores.map(score => score.TScoreInPounds)),
    };
    return maxScoresFound;
  }
  const [maxScores] = useState(findMaxScores(theScores))
  let minHeight = 20
  let maxHeight = 60;
  let unitHeight = 2 * maxHeight / maxScores.MaxTScoreInPounds

  return (
    <section className="bg-gradient-to-br from-[#1c013d] to-[#2e0249] p-8 font-montserrat z-[100] flex flex-wrap max-w-[1350px] mqAllDesktop:mx-auto mqAll:mx-auto mqAllMobile:!mx-1 gap-8 mb-[60px] mqAllMobile:mb-0 mq1157:mx-auto mqAllMobile:p-2 items-center">
      <div className="flex flex-col mq650:w-full w-[600px] mq1157:w-full min-w-[403px] mqAllMobile:min-w-[340px]">
        <p className="text-sm text-[#e5e5e5] text-[12px] w-[340px] mx-auto text-left bg-opacity-20 bg-blue-900 text-blue-400 transition-all rounded p-1 mt-0 mb-2">
          *Average Yearly Expenses for a Senior Software Engineer.
        </p>
        <div className="grid grid-cols-3">
          {theScores.map((score, index) => {
            let calcBFTscore = unitHeight ? (minHeight + (unitHeight * score.BTFScoreInPounds)) : undefined
            let calcSscore = unitHeight ? (minHeight + (unitHeight * score.SScoreInPounds)) : undefined
            return (<div
              key={index}
              className={`bg-gray-800 h-[310px] min-h-[310px] relative p-6 mqAllMobile:p-3 ${index === 0 ? 'rounded-l-lg' : ''} ${index === theScores.length - 1 ? 'rounded-r-lg' : ''} border-gray-700`}
            >
              <div className="flex items-center justify-center">
                <img src={score.flag} alt={`${score.country} Flag`} className="w-8 h-8 mr-2 rounded-8xl" />
                <h2 className="text-2xl font-bold text-white">{score.country}</h2>
              </div>
              <div className='w-full bottom-0 ml-[-24px] mqAllMobile:ml-[-12px] absolute'>
                <div className="flex flex-col mb-3 mx-8 ">
                  <div className="text-center">
                    <div className={`h-20 bg-pink-500 rounded-t-lg flex justify-center items-center`} style={calcBFTscore && { height: `${calcBFTscore}px` }}>
                      <p className="text-xl text-gray-200 mqAllMobile:text-smi"><b>£{score.BTFScoreInPounds}k {score.BTFOptionalScoreInPounds ? `($${score.BTFOptionalScoreInPounds}k)` : ""}</b></p>
                    </div>
                    <div className={`h-20 bg-[#64B5F6] rounded-b-lg flex justify-center items-center`} style={calcSscore && { height: `${calcSscore}px` }}>
                      <p className="text-xl text-gray-200 mqAllMobile:text-smi"><b>£{score.SScoreInPounds}k {score.SOptionalScoreInPounds ? `($${score.SOptionalScoreInPounds}k)` : ""}</b></p>
                    </div>
                  </div>
                </div>
                <div className="text-center mx-auto">
                  <p className="text-3xl font-bold text-white mb-0 mqAllMobile:text-mid mq400:text-[15px]">£{score.TScoreInPounds}k {score.TOptionalScoreInPounds ? `($${score.TOptionalScoreInPounds}k)` : ""}</p>
                  <div className='flex items-center justify-center'>
                    <p className="mqAllMobile:text-smi text-[#e5e5e5]">{score.savings ? (<b>£{score.savings}k savings</b>) : <b>Best of All</b>}</p>
                    {score.savings === 0 && (
                      <img src="/checked-6-1@2x.png" alt="Best" className="w-[25px] h-[26px] ml-2" />
                    )}
                  </div>
                </div>
              </div>
            </div>
            )
          })}
        </div>
        <div className="w-full bg-yellow-500 rounded-b-lg border-t border-gray-700">
          <div className="text-2xl py-2 font-bold text-center text-gray-900">83% Cost Reduction (US)</div>
        </div>
        <div className="flex justify-center items-center mt-4 bg-opacity-20 bg-blue-900 text-blue-400 transition-all rounded">
          <div className="mr-6 flex items-center">
            <span className="bg-pink-500 w-4 h-4 rounded-full inline-block mr-2"></span>
            <span className="text-pink-500 text-sm">Benefits + Taxes + Fees</span>
          </div>
          <div className="flex items-center">
            <span className="bg-[#64B5F6] w-4 h-4 rounded-full inline-block mr-2"></span>
            <span className="text-[#64B5F6] text-sm">Salary</span>
          </div>
        </div>
      </div>
      <div className='w-[420px] relative bg-gray-800 rounded-lg px-[20px] mqAllMobile:px-2 mq1157:w-full'>
        <div className="text-center mt-8 flex-col mq1157:mt-4">
          <h2 className="text-2xl font-bold text-white text-left mqAllMobile:text-lg">The best of the best are now in your budget.</h2>
          <p className="text-[#e5e5e5] mt-2 text-left text-[16px] mqAllMobile:text-smi">
            As industry experts, we specialise in finding and hiring the exact talent or
            team your project needs. Itʼs as simple as sharing your team requirements
            with us. If you're unsure, we offer quick consultation sessions to help
            identify the ideal team for your project. We handle the entire
            process—recruitment, interviews, providing hardware, <b>office space (so
              your team can be together)</b>, payroll, and all contractual work—so you
            donʼt need to invest in building a recruitment team or managing
            administrative overhead like national insurance and other fees.<br /><br />
            By partnering with our <b>UK-based company</b>, you avoid the complexities of
            international operations. Youʼll have a straightforward contract between
            two companies for a defined term, ensuring compliance with UK
            regulations and a hassle-free experience. Plus, by outsourcing globally, we
            help you optimise costs and achieve the best value for your investment.
          </p>
        </div>
      </div>
    </section>
  );
}

export default CostCalculatorComponent;