
import PropTypes from "prop-types";
import { useBrowser } from "../pages/Provider/BrowserContext";
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export const Image = ({ title, desc, image, direction, w, key1, h, isLast, mainTitle }) => <div style={!mainTitle ? { marginTop: h / 4 - (w > 550 ? 106 : 80), marginBottom: (h / 4 - (w > 550 ? 106 : 80)) } : {}}><div data-aos={key1 % 2 == 0 ? "fade-right" : "fade-left"} className="self-stretch flex flex-row-reverse mq1157:flex-col-reverse items-center justify-center gap-[50px] max-w-full mqAllMobile:gap-0 mq900:gap-0 mq1725:flex-wrap [backdrop-filter:blur(10px)] [background:linear-gradient(95.76deg,_rgba(255,_255,_255,_0.04),_rgba(255,_255,_255,_0.06)_26.43%,_rgba(255,_255,_255,_0.06)_77.28%,_rgba(255,_255,_255,_0.03))] box-border rounded-xl" style={{ flexDirection: w > 1134 ? direction : {} }}>
  <div className="flex-[0.8289] flex flex-col items-start justify-start py-5 box-border min-w-[522px] max-w-full mqAllMobile:box-border mq900:box-border mq900:min-w-full mq1725:flex-1 px-7 mq575:px-2.5 gap-5">
    <div className="self-stretch flex flex-row items-center justify-start box-border max-w-full">
      <h1 className="m-0 flex-1 relative text-inherit font-inherit inline-block max-w-full mqAllMobile:!text-xl mq900:text-13xl font-montserrat font-bold">
        {title}
      </h1>
    </div>
    <div className="self-stretch flex flex-row items-start justify-start box-border max-w-full text-justify text-mid mqAllMobile:text-smi font-poppins">
      <p className="m-0 flex-1 relative inline-block max-w-full">
        {desc}
      </p>
    </div>
  </div>
  <div className="flex-1 flex flex-col items-start justify-start p-2.5 box-border min-w-[522px] max-w-full mq900:min-w-[520px] mqAllMobile:!min-w-full mq1725:flex-1">
    <img
      className="self-stretch h-[360px] mq1250:h-[380px] mq750:h-[300px] mqAllMobile:!h-[200px] mqAllMobile:!w-10/12 mqAllMobile:mx-auto relative rounded-12xl max-w-full overflow-hidden shrink-0 object-cover"
      loading="lazy"
      alt=""
      src={image}
    />
  </div>
</div></div>

const OurProcess = ({ className = "", ourProcessContent, title, oneSided }) => {
  const w = useBrowser().windowDimensions.width
  const h = useBrowser().windowDimensions.height
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div className="flex-1 overflow-hidden flex flex-col items-center justify-center py-[20.5px] px-5 mq750:px-4 mqAllMobile:!px-2 box-border gap-[62px] max-w-full z-[1] mqAllMobile:!gap-[15px] mq900:gap-[31px] mq1325:pt-5 mq1325:pb-5 mq1325:box-border">
      {title && <h1 className="m-0 w-[1680px] relative text-inherit font-bold font-inherit inline-block max-w-full mq900:text-29xl mq1920:text-32xl mqAll:text-37xl text-white text-center mt-12 mqAllMobile:!text-3xl mqAllMobile:!mt-2">
        {title}
      </h1>}
      <div
        className={`w-[1680px] flex flex-col items-center justify-start gap-[60px] max-w-full text-left text-21xl text-white font-montserrat mq900:gap-[30px] mqAllMobile:!gap-[15px] ${className}`}
      >
        {ourProcessContent.map((step, index) =>
          (oneSided ? oneSided === "left" : step.alignment === "left") ? (
            <Image
              key1={index}
              key={index}
              title={step.title}
              mainTitle={title}
              desc={step.desc}
              image={step.image}
              direction={"row-reverse"}
              w={w}
              h={h}
              isLast={ourProcessContent.length - 1 == index}
            />
          ) : (
            <Image
              key1={index}
              key={index}
              title={step.title}
              mainTitle={title}
              desc={step.desc}
              image={step.image}
              direction={"row"}
              w={w}
              h={h}
              isLast={ourProcessContent.length - 1 == index}
            />
          )
        )}
      </div>
    </div>
  );
};

OurProcess.propTypes = {
  className: PropTypes.string,
};

export default OurProcess;
