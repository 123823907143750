import React, { useState, useEffect } from "react";
import { useBrowser } from "../pages/Provider/BrowserContext";

const testimonials = [
    {
        id: 1,
        logo: "/astss.jpg",
        compIco: "/Showmans.jpg",
        feedback: "\"I've worked numerous times with Shawak at Vantage Cybertech, who has delivered projects such as our website that we are very pleased with. They have also helped us hire and contract editors offshore, they make it easy and straightforward, handling everything.\"",
        name: "Atul Sharma",
        role: "Founder of The Showman's Studio",
    },
    {
        id: 2,
        logo: "/aha2h.jpg",
        compIco: "/A2H-Digital.jpg",
        feedback:
            "\"I have massively appreciated working with Vantage Cybertech, they helped me deliver numerous projects to my clients, by providing me with contracted developers as well as a project that was independently handled by them. Great communication, very supportive, and go above and beyond.\"",
        name: "Awais Hussain",
        role: "Founder of A2H Digital",
    },
    {
        id: 3,
        logo: "/aggv.jpg",
        compIco: "/Gacslogo.jpg",
        feedback: "\"Shawak was lovely to work with, I was able to expand my team of developers when we were full on capacity, it was a completely smooth process and was well within my budget. Highly recommend!\”",
        name: "Anmol Goel",
        role: "Founding Partner & CEO at Gacs Ltd & Gacsym Ventures",
    },
    {
        id: 4,
        // logo: "/jhjcsb.jpg",
        logo: "/AnonymousMale.jpg",
        compIco: "/Jaafar.png",
        feedback:
            "\"Vantage Cybertech handles the software side of my business, they helped me automate a lot of my operations and improve overrall SEO. I noticed a lot more traffic into my business. Pleasure to work with!\"",
        name: "Jaafar El-Hassan",
        role: "Owner of Jaafar Car Specialist Broker",
    },
    {
        id: 5,
        logo: "/mp2u.jpeg",
        compIco: "/P2U.jpg",
        feedback:
            "\"Shawak and his team are not just building a robust and seamless online platform for Parties2You; they are truly invested in understanding the core values and goals of my business. Their attention to detail, dedication to quality, and personalised approach have made all the difference in ensuring that the solutions they provide align perfectly with my vision. I would highly recommend Vantage Cybertech!\"",
        name: "Mateo Suarez",
        role: "Owner of Parties2You",
    },
    {
        id: 6,
        logo: "/Giles_Casson.png",
        compIco: "/enconstaffinglogo.png",
        imageIcoComp: (w, img, color) => <div
            className={"p-3 flex justify-center items-center w-44 mqAllMobile:w-20 mqAllMobile:p-1 rounded-lg shadow-xl bg-white"}
            style={{
                backgroundColor: color ? color : "white"
            }}
        >
            <img
                className="w-full"
                src={img}
                alt={img.split("/")[1].split(".")[0]}
                width={w < 551 ? 75 : 200}
            />
        </div>,
        feedback:
            "\"It was a pleasure to work with Vantage Cybertech, they took the responsibility to handle our website and other software services we use internally. Shawak gave excellent advice that improved many aspects of the business. This is an excellent team to work with, I’ve had nothing but positive experiences.\"",
        name: "Giles Casson",
        role: "Company Director at Encon Staffing",
    },
];

const Testimonials = ({ ClassName, autoScroll = true, scrollInterval = 10000 }) => {
    const w = useBrowser().windowDimensions.width;
    const [currentIndex, setCurrentIndex] = useState(0);
    const [fadeClass, setFadeClass] = useState("resize-in fade-in");
    const modifiedTestimonials = (myLength, pos) => {
        const extendedTestimonials = testimonials.concat(testimonials);
        return extendedTestimonials.slice(pos, pos + myLength);
    };
    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
        );
    };
    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
        );
    };
    useEffect(() => {
        if (!autoScroll) return;
        const interval = setInterval(() => {
            setFadeClass("resize-in fade-in");
            handleNext();
            setTimeout(() => {
                setFadeClass("resize-out fade-out");
            }, scrollInterval - 500);
        }, scrollInterval);
        return () => clearInterval(interval);
    }, [autoScroll, scrollInterval]);

    return (
        <div
            className={
                `py-10 mqAllMobile:py-0 text-white flex flex-col max-w-[1400px] mqAllMobile:!px-1 w-full items-center self-center px-5 mq575:px-0 box-border text-left text-13xl text-gray-200 font-poppins ${ClassName}`
            }
        >
            <div className="text-center mq575:my-0 my-[50px]">
                <h2 className="text-32xl font-bold text-white font-montserrat mq825:text-29xl mq550:text-12xl mq575:mx-5 mq425:text-4xl mq450:w-[300px] mqAllMobile:!text-2xl mqAllMobile:mt-0">
                    What others say about us.
                </h2>
            </div>
            <div className="relative mx-auto px-4">
                <div className="flex items-center justify-between">
                    <button
                        onClick={handlePrev}
                        aria-label="Previous testimonials"
                        className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-purple-600 p-3 rounded-full hover:bg-purple-800 focus:outline-none focus:ring focus:ring-purple-400 z-[200]"
                    >
                        &#8249;
                    </button>
                    <div className="flex flex-wrap justify-center w-full transition-transform duration-500">
                        {modifiedTestimonials((w > 1200 ? 3 : w > 900 ? 2 : 1), currentIndex).map((testimonial, index) => (
                            <div
                                key={testimonial.id}
                                className={"flex-1 relative shadow-[0px_6.7px_11.16px_rgba(0,_0,_0,_0.03)] rounded-[8.93px] bg-gainsboro-400 box-border flex flex-col items-center justify-start py-10 px-[34px] mqAllMobile:px-[14px] gap-[30.4px] min-w-[350px] border-[1.1px] border-solid border-light-100 mqAllMobile:gap-[10px] mqAllMobile:py-[20px] mq450:box-border mq900:min-w-full min-h-full " + (index === 3 && "hidden ") + " " + (fadeClass)}
                            >
                                {testimonial?.imageIcoComp ? (testimonial.imageIcoComp(w, testimonial.compIco)) : <img
                                    src={testimonial.compIco}
                                    alt="Company Logo"
                                    loading="lazy"
                                    className="mx-auto rounded-lg border-2 border-purple-400"
                                    width={200}
                                    height={76}
                                />}
                                <p className="text-[18px] mqAllMobile:text-smi mq425:mx-2 font-montserrat text-left min mq425:min-h-[70px] w-full">
                                    {testimonial.feedback}
                                </p>
                                <div className="flex w-full mt-auto items-center justify-center">
                                    <div className="flex items-center">
                                        <img
                                            src={testimonial.logo}
                                            alt="Company Logo"
                                            loading="lazy"
                                            className="w-16 h-16 mx-auto object-cover rounded-full border-2 border-purple-400"
                                        />
                                    </div>
                                    <div className="text-center w-full space-y-1 ml-6">
                                        <p className="font-bold text-lg text-purple-400 text-right mqAllMobile:my-0">
                                            {testimonial.name}
                                        </p>
                                        <p className="text-gray-400 text-[16px] font-montserrat text-right">
                                            {testimonial.role}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <button
                        onClick={handleNext}
                        aria-label="Next testimonials"
                        className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-purple-600 p-3 rounded-full hover:bg-purple-800 focus:outline-none focus:ring focus:ring-purple-400"
                    >
                        &#8250;
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Testimonials;


// import React, { useState, useEffect } from "react";
// import { useBrowser } from "../pages/Provider/BrowserContext";

// const Testimonials = ({ ClassName, autoScroll = true, scrollInterval = 10000 }) => {
//     const w = useBrowser().windowDimensions.width;
//     const [currentIndex, setCurrentIndex] = useState(0);
//     const modifiedTestimonials = (myLength, pos) => {
//         const extendedTestimonials = testimonials.concat(testimonials);
//         return extendedTestimonials.slice(pos, pos + myLength);
//     };
//     const handlePrev = () => {
//         setCurrentIndex((prevIndex) =>
//             prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
//         );
//     };
//     const handleNext = () => {
//         setCurrentIndex((prevIndex) =>
//             prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
//         );
//     };
//     useEffect(() => {
//         if (!autoScroll) return;
//         const interval = setInterval(() => {
//             handleNext();
//         }, scrollInterval);
//         return () => clearInterval(interval);
//     }, [autoScroll, scrollInterval]);
//     return (
//         <div
//             className={
//                 `py-10 mqAllMobile:py-0 text-white flex flex-col max-w-[1400px] mqAllMobile:!px-1 w-full items-center self-center px-5 mq575:px-0 box-border text-left text-13xl text-gray-200 font-poppins ${ClassName}`
//             }
//         >
//             <div className="text-center mq575:my-0 my-[50px]">
//                 <h2 className="text-32xl font-bold text-white font-montserrat mq825:text-29xl mq550:text-12xl mq575:mx-5 mq425:text-4xl mq450:w-[300px] mqAllMobile:!text-2xl mqAllMobile:mt-0">
//                     What others say about us.
//                 </h2>
//             </div>
//             <div className="relative mx-auto px-4">
//                 <div className="flex items-center justify-between">
//                     <button
//                         onClick={handlePrev}
//                         aria-label="Previous testimonials"
//                         className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-purple-600 p-3 rounded-full hover:bg-purple-800 focus:outline-none focus:ring focus:ring-purple-400 z-[200]"
//                     >
//                         &#8249;
//                     </button>
//                     <div className="flex flex-wrap justify-center w-full transition-transform duration-500" style={{ transform: `translateX(-${currentIndex * (w > 1200 ? 33.33 : w > 900 ? 50 : 100)}%)` }}>
//                         {modifiedTestimonials(w > 1200 ? 3 : w > 900 ? 2 : 1, currentIndex).map((testimonial) => (
//                             <div
//                                 key={testimonial.id}
//                                 className="flex-1 relative shadow-[0px_6.7px_11.16px_rgba(0,_0,_0,_0.03)] rounded-[8.93px] bg-gainsboro-400 box-border flex flex-col items-center justify-start py-10 px-[34px] mqAllMobile:px-[14px] gap-[30.4px] min-w-[350px] border-[1.1px] border-solid border-light-100 mqAllMobile:gap-[10px] mqAllMobile:py-[20px] mq450:box-border mq900:min-w-full min-h-full"
//                             >
//                                 <img
//                                     src={testimonial.compIco}
//                                     alt="Company Logo"
//                                     loading="lazy"
//                                     className="mx-auto rounded-lg border-2 border-purple-400"
//                                     width={200}
//                                     height={76}
//                                 />
//                                 <p className="text-[18px] mqAllMobile:text-smi mq425:mx-2 font-montserrat text-left min mq425:min-h-[70px] w-full">
//                                     {testimonial.feedback}
//                                 </p>
//                                 <div className="flex w-full mt-auto items-center justify-center">
//                                     <div className="flex items-center">
//                                         <img
//                                             src={testimonial.logo}
//                                             alt="Company Logo"
//                                             loading="lazy"
//                                             className="w-16 h-16 mx-auto object-cover rounded-full border-2 border-purple-400"
//                                         />
//                                     </div>
//                                     <div className="text-center w-full space-y-1 ml-6">
//                                         <p className="font-bold text-lg text-purple-400 text-right mqAllMobile:my-0">
//                                             {testimonial.name}
//                                         </p>
//                                         <p className="text-gray-400 text-[16px] font-montserrat text-right">
//                                             {testimonial.role}
//                                         </p>
//                                     </div>
//                                 </div>
//                             </div>
//                         ))}
//                     </div>
//                     <button
//                         onClick={handleNext}
//                         aria-label="Next testimonials"
//                         className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-purple-600 p-3 rounded-full hover:bg-purple-800 focus:outline-none focus:ring focus:ring-purple-400"
//                     >
//                         &#8250;
//                     </button>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Testimonials;
