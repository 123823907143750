import React, { useEffect, useRef, useState } from 'react';
import Header from '../components/Header';
import { Footer } from "../components/FooterTop";
import AOS from 'aos';
import 'aos/dist/aos.css';

const RenderScreenshot = ({ src, alt, cls, isActive }) => {
    return src ? (
        <img
            src={src}
            loading="lazy"
            alt={`${alt} Case Study`}
            className={
                `w-full h-auto object-contain transition-transform duration-500 ` +
                cls +
                ` ` +
                (isActive == false ? "opacity-50" : "opacity-100")
            }
        />
    ) : (
        <div className="h-40 w-full bg-gray-200 flex items-center justify-center text-gray-500 mb-2">
            <span>No Image</span>
        </div>
    );
};

export const ScrollComponent = ({classes}) => (<div className={'flex flex-col text-gray-100 font-poppins relative h-[150px] w-[147px] mx-auto '+classes}>
    <div className='absolute w-full text-center mt-3'>Scroll Down</div>
    <img
        className=""
        alt=""
        src="/ScrollAnimation.gif"
    />
</div>)

const WebsiteCard = ({ index, title, description, logo, imgSrc, url, logoTheme, w, h, needOfTitle, logoBG, heightScreen, isLast, widthScreen }) => (
    <div data-aos={index % 2 ? "fade-left" : "fade-right"} className={`container relative max-w-[1400px] mx-auto z-40 mqAllMobile:mb-0 transition-transform duration-500`} style={{ ...{ paddingTop: heightScreen / 4 - (widthScreen > 550 ? 106 : 80), paddingBottom: (heightScreen / 4 - (widthScreen > 550 ? 106 : 80)) }, ...(isLast ? { marginBottom: (heightScreen * 2 / 8 - (widthScreen > 550 ? 106 : 80)) } : {}) }}>
        <div className={`self-stretch overflow-hidden flex flex-row items-center justify-center pr-5 pl-[21px] min-h-[317px] gap-[89px] mq1250:min-h-[300px] mq1250:py-[50px] max-w-full z-[1] bg-opacity-50 mqAllMobile:!py-3`}>
            <div className="p-5 mqAllMobile:p-2 rounded-md w-full flex flex-col md:flex-row space-x-8 bg-white bg-opacity-10 !bg-black/[.5] backdrop-blur-sm">
                <a className="w-full no-underline" href={url}>
                    <div className={`flex ${index % 2 === 1 ? 'flex-row-reverse' : 'flex-row'} md:flex-col w-full gap-x-8 justify-center items-center  mq650:flex-col gap-5 mq650:gap-8`}>
                        <div className="flex flex-col items-center justify-center text-white">
                            <div className={`flex items-center justify-between ${index % 2 === 0 ? 'md:flex-row-reverse' : 'md:flex-row'} mb-3 mqAllMobile:mb-0`}>
                                <div className={`p-1 h-fit w-auto rounded-md ${logoTheme === 'dark' ? 'bg-white w-fit' : ''}`} style={logoBG ? { backgroundColor: logoBG } : {}}>
                                    <img
                                        src={logo}
                                        alt={`${title} Logo`}
                                        loading="lazy"
                                        className="lazyloaded"
                                        width={w ? w : "234"}
                                        height={h ? h : "50"}
                                    />
                                </div>
                                {needOfTitle && <div className="ml-4 font-montserrat">
                                    <h3>{title}</h3>
                                </div>}
                            </div>
                            <div className="mainContainer relative p-3 text-justify rounded-md">
                                <div className="relative z-10 font-poppins">
                                    <div className='mqAllMobile:text-smi mqAllDesktop:m-[20px] mq750:m-1 mqAllMobile:!m-0'>{description}</div>
                                </div>
                            </div>
                        </div>
                        <div className='mq1024:min-w-[250px]'>
                            <RenderScreenshot src={imgSrc} alt={title} />
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
);

const Portfolio = () => {
    const [w, setW] = useState(window.innerWidth);
    const [h, setH] = useState(window.innerHeight);
    const [focusedCard, setFocusedCard] = useState(null);
    const [activeCards, setActiveCards] = useState([]);
    const cardRefs = useRef([]);
    const imageRefs = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    const imageRef = entry.target;
                    if (entry.isIntersecting) {
                        imageRef.classList.add('zoom-fade-active');
                        // imageRef.classList.add('z-10');
                    } else {
                        // imageRef.classList.remove('z-10');
                        imageRef.classList.remove('zoom-fade-active');
                    }
                });
            },
            {
                threshold: 0.6, // Trigger when at least 50% of the image is in the viewport
            }
        );

        imageRefs.current.forEach(ref => {
            if (ref) observer.observe(ref);
        });

        const handleResize = () => {
            setW(window.innerWidth);
            setH(window.innerHeight);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
            imageRefs.current.forEach(ref => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setActiveCards(prev => [...prev, entry.target.id]);
                        setFocusedCard(entry.target.id); // Set the focused card
                    } else {
                        setActiveCards(prev => prev.filter(id => id !== entry.target.id));
                        if (focusedCard === entry.target.id) {
                            setFocusedCard(null); // Reset focused card if it goes out of view
                        }
                    }
                });
            },
            { threshold: 0.5 }
        );

        cardRefs.current.forEach(ref => {
            if (ref) observer.observe(ref);
        });

        return () => {
            cardRefs.current.forEach(ref => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, [focusedCard]);

    const websites = [
        {
            url: 'https://ummazing.com/',
            name: 'Ummazing',
            description: 'Ummazing.com is your ultimate platform for discovering Muslim-friendly businesses. With over 15500 businesses and counting.',
            screenshots: '/Ummazing1.png',
            logo: 'https://ummazing.com/_next/image?url=%2Fassets%2Fummazing.png&w=384&q=75',
            needOfTitle: true,
            bgImg: "/Ummazing_bg.png",
            w: 69.5,
            h: 69.5
        },
        {
            url: 'https://whitehallclinic.com/',
            name: 'Whitehall Clinic',
            description: 'Introducing the Whitehall Clinic’s new online presence, designed to reflect its status as a leading private medical practice in central Leeds.',
            screenshots: '/WhiteHall.png',
            logoTheme: 'dark',
            logo: 'https://www.whitehallclinic.com/images/logo.webp',
            bgImg: "/Whitehall_Bg.png",
            w: 190,
            h: 60.5
        },
        {
            url: 'https://enconstaffing.com/',
            name: 'Encon Staffing',
            description: 'Explore Encon Staffing’s new website, dedicated to connecting top talent with leading industries including construction, cleaning, protective coating and healthcare.',
            screenshots: '/enconstaffing.png',
            logo: 'https://enconstaffing.com/wp-content/uploads/2023/02/logo.png',
            logoTheme: 'dark',
            bgImg: "/Encon_Bg.png",
            w: 210,
            h: 60.5
        },
        {
            url: 'https://awesomeoutreach.com/',
            name: 'Awesome Outreach',
            description: 'A professional website that assists Awesome Outreach’s goal to automate LinkedIn and emails to generate leads and sales for businesses.',
            screenshots: '/awesomeoutreach.png',
            logo: 'https://awesomeoutreach.com/wp-content/uploads/2023/06/cropped-AO-Logo.png',
            logoTheme: 'dark',
            bgImg: "/Awesome_Bg.png",
            w: 147,
            h: 69.5
        },
        {
            url: 'https://cartsi.app/',
            name: 'Cartsi',
            description: 'An app that allows you to scan and pay for items, and checkout all within the app. Allowing you to skip long queues and keep digital receipts. Includes features like barcode scanning, location based search, payment handling and more.',
            screenshots: '/Cartsi/Frame_1618873037.svg',
            logo: '/Cartsi/Logo.png',
            logoTheme: 'dark',
            bgImg: "/Cartsi/Cartsi_Bg.png",
            w: 170,
            h: 69.5
        },
        {
            // url: 'https://18.132.235.170/',
            name: 'Parties2You',
            description: 'A platform for freelancers in the entertainers niche to perform at events. Including live notifications, a booking system, payment processing, admin and user accounts, chat functionality and more.',
            screenshots: '/P2U_Responsive2.png',
            logo: '/P2U.jpg',
            logoTheme: 'dark',
            bgImg: "/P2U_Bg.png",
            w: 210,
            h: 69.5,
            logoBG: "#83fed9"
        }
    ];

    useEffect(() => {
        AOS.init();
    }, [])

    return (
        <div className="w-full relative min-h-screen bg-black overflow-hidden flex flex-col items-start justify-start leading-normal tracking-normal">
            <div className="absolute w-[2487px] h-[7910px] bottom-[-3620px] left-[-291px] z-0">
                <img
                    className="w-full h-full object-contain absolute left-0 top-[-86px] transform scale-[1.022]"
                    alt=""
                    src="/element.svg"
                />
            </div>

            <Header />

            <div className={`mainContainer flex flex-col justify-center items-center box-border relative mx-auto text-center py-12 mqAllMobile:py-0 px-6`} style={{ height: h - 106 }}>
                <div>
                    <h2 className="relative z-[2] text-white font-bold mb-6 font-montserrat text-23xl mqAllMobile:text-4xl mqAllMobile:!mt-0 mqAllMobile:!mb-2">Our Portfolio</h2>
                    <p className="relative z-[2] text-lg text-white max-w-3xl mx-auto text-center font-poppins mqAllMobile:text-base mqAllMobile:!mt-0">
                    We believe your website or app should be a true reflection of the quality of your business, as it often serves as the first impression for many of your customers. We’re here to team up with you, understand your vision, and make that a reality. Here are some examples of the projects we have brought to life previously.
                    </p>
                </div>
                <ScrollComponent />
            </div>
            <div className="w-full z-40">
                {websites.map((site, index) => (
                    <div
                        key={site.name}
                        id={`website-card-${index}`}
                        className="mx-auto relative h-full"
                        ref={el => (cardRefs.current[index] = el)}
                    >
                        <div
                            className={`absolute top-0 left-0 h-full w-full bg-cover zoom-fade blur-[5px]`}
                            style={{ background: `linear-gradient(0deg, #af57f73a, #00000040), url(${site?.bgImg}) no-repeat top / cover` }}  // Dynamic background image
                            ref={el => (imageRefs.current[index] = el)}
                        />
                        <WebsiteCard
                            index={index}
                            w={site.w}
                            h={site.h}
                            heightScreen={h}
                            widthScreen={w}
                            title={site.name}
                            logoTheme={site.logoTheme ? site.logoTheme : ''}
                            description={site.description}
                            logo={site.logo}
                            imgSrc={site.screenshots}
                            url={site.url}
                            needOfTitle={site?.needOfTitle}
                            logoBG={site?.logoBG}
                            isActive={activeCards.includes(`website-card-${index}`)}
                            focused={focusedCard === `website-card-${index}`}
                            isLast={index + 1 == websites.length}
                        />
                    </div>
                ))}
            </div>
            <Footer />
        </div>
    );
};

export default Portfolio;
