import AnimateComponentScroll from "../components/AnimateComponentScroll";
import { Footer } from "../components/FooterTop";
import Header from "../components/Header";
import OurProcess from "../components/OurProcess";
import PackageFeatures from "../components/PackageFeatures";
import { ScrollComponent } from "./PortFolioPage"
import React, { useState } from 'react';

const ourProcessContent = [
    {
        title: "Screening - Saving You Time",
        desc: `At VCT, we streamline your hiring process by sourcing and thoroughly vetting candidates. This includes assessing their English proficiency, verifying relevant work experience, and gauging their genuine interest in your company. By carefully filtering out roughly half of the applicants, we ensure only the most promising candidates progress, saving you valuable time and effort.`,
        image: "/Screening–Saving-You-Time-1.jpeg", // Replace with the actual image path
        bg: "https://images.pexels.com/photos/3760081/pexels-photo-3760081.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        alignment: "left", // Define whether the content aligns left or right
    },
    {
        title: "Technical Interview - Evaluating Tech Stack Knowledge",
        desc: `We perform detailed technical interviews to evaluate each candidate’s knowledge and expertise in your required tech stack. This process ensures their skills align with your technical requirements by assessing their understanding of core concepts and practical applications. By eliminating half of the candidates during this step, we present you with only highly qualified professionals.`,
        image: "/Technical-Interview–Evaluating-Tech-Stack-Knowledge-1.jpeg", // Replace with the actual image path
        bg: "https://images.pexels.com/photos/7369/startup-photos.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        alignment: "right",
    },
    {
        title: "Coding Challenge - Real-World Problem Solving",
        desc: `Our coding challenge replicates real-world scenarios to test candidates' ability to solve complex problems. This stage emphasizes critical thinking, creativity, and practical coding efficiency. Candidates who don’t meet our high standards are filtered out, leaving only those with outstanding technical skills. Roughly one-third of the remaining applicants are selected to move forward.`,
        image: "/Coding-Challenge–Real-World-Problem-Solving-1.jpeg", // Replace with the actual image path
        bg: "https://images.pexels.com/photos/16129724/pexels-photo-16129724/free-photo-of-man-working-on-computers-coding.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        alignment: "left",
    },
    {
        title: "Cultural Interview - Behavioral Fit",
        desc: `Beyond technical skills, a successful hire must align with your company’s values and culture. In this stage, we assess communication, collaboration, and problem-solving through a rigorous cultural interview. Candidates who fail to meet our high behavioral standards are eliminated. Only those with the right mindset and interpersonal skills advance to the final step.`,
        image: "/Cultural-Interview–Behavioral-Fit-1.jpeg", // Replace with the actual image path
        bg: "https://images.pexels.com/photos/30002255/pexels-photo-30002255/free-photo-of-silhouetted-group-in-sunlit-modern-interior.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        alignment: "right",
    },
    {
        title: "Your Final Interview",
        desc: `After passing all prior stages, candidates are ready for your team’s final interview. This step provides you with an opportunity to confirm their alignment with your company’s culture, goals, and expectations. It allows you to evaluate their fit first-hand, ensuring the best choice for your team. This step finalizes the hiring decision.`,
        image: "/Your-Final-Interview-1.jpeg", // Replace with the actual image path
        bg: "https://images.pexels.com/photos/6814524/pexels-photo-6814524.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        alignment: "left",
    },
];

const VCTOffshore = () => {
    const [h, setH] = useState(window.innerHeight);
    const [w, setW] = useState(window.innerHeight);

    const handleResize = () => {
        setH(window.innerHeight)
        setW(window.innerWidth)
    };

    window.addEventListener("resize", handleResize);

    return (
        <div className="w-full relative min-h-screen bg-black overflow-hidden flex flex-col items-start justify-start leading-[normal] tracking-[normal]">
            <div className="w-[2487px] h-[7910px] absolute !m-[0] left-[-291px] flex items-center justify-center z-5">
                <img
                    className="w-full h-full object-contain absolute left-[0px] top-[-86px] [transform:scale(1.022)]"
                    alt="bg-full-image"
                    src="/element.svg"
                />
            </div>
            <Header />
            <div className="hero w-full flex flex-col justify-center items-center mx-auto" style={{ height: h - 106 }}>
                <div className="self-stretch flex flex-col items-start justify-start gap-[40px] max-w-full text-left text-45xl mq900:gap-[20px] mt-8 mqAllMobile:mt-0">
                    <div className="max-w-[1450px] self-center flex flex-row items-center justify-center mq1450:gap-[20px] gap-[40px] mq1725:flex-wrap w-full mx-5 mqAllMobile:mx-0">
                        <h1 className="m-0 w-[690px] relative text-inherit font-bold font-inherit inline-block shrink-0 min-w-[690px] max-w-full z-[1] mq450:text-9xl mq900:text-32xl mq1325:min-w-full mq1725:flex-1 text-white text-center font-montserrat mq550:m-1 mq550:text-13xl">How will we source the talent?</h1>
                        <div className="w-[690px] flex flex-col items-start justify-start px-0 pb-0 box-border min-w-[690px] max-w-full text-center text-base text-gray-400 mq900:min-w-full mq1725:flex-1">
                            <div className="self-stretch flex flex-col items-start justify-start gap-[46px] mq450:gap-[23px]">
                                <p className="self-stretch relative whitespace-pre-wrap shrink-0 [debug_commit:bf4bc93] font-poppins text-[16px] mqAllMobile:text-smi z-[1] mq550:m-2 mx-5">
                                    Our comprehensive five-step vetting system identifies the best talent from thousands of candidates.
                                    We focus on sourcing exceptional individuals who are often overlooked by common online platforms,
                                    ensuring you connect with top-tier professionals.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="self-stretch flex flex-row items-start py-0 px-[70px] box-border max-w-full mq1325:pl-[35px] mq1325:pr-[35px] mqAllMobile:!px-0 mq1325:box-border mb-6 mt-3 mqAllMobile:my-2 justify-center">
                    <img
                        className="h-[448px] rounded-[40px] mq900:rounded-2xl mqAllMobile:mx-3 mqAllMobile:!h-[260px] flex-1 relative mq750:h-fit max-w-fit overflow-hidden object-contain mx-auto"
                        alt=""
                        src="/WhyOffshoreScalingMatters.jpeg"
                    />
                </div>
                <ScrollComponent classes={(h < 900) && (550 < w) && "mqAllDesktop:hidden"} />
            </div>
            <div className="self-center w-[1400px] flex flex-row items-start justify-start pt-0 px-0 pb-[148px] box-border max-w-full font-montserrat mq900:pb-2 mq900:box-border mq1325:pb-[62px] mq1325:box-border">
                {/* <OurProcess ourProcessContent={ourProcessContent} oneSided={"right"} /> */}
                <AnimateComponentScroll ourProcessContent={ourProcessContent} />
            </div>
            <Footer page={"static"} />
        </div>
    );
};

export default VCTOffshore;
