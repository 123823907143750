import CardColumns from "./CardColumns";
import PropTypes from "prop-types";

const FrameComponent1 = ({ className = "" }) => {
  return (
    <div className={`self-stretch flex flex-row items-start justify-center py-0 px-1.5 box-border max-w-full text-left text-45xl text-white font-montserrat mq825:mt-10 mqAllMobile:!mt-0 mqAllMobile:!px-0 mqAllMobile:!w-full ${className}`}>
      <div className={`max-w-[1400px] flex-1 flex flex-col items-start justify-start gap-[64px] mqAllMobile:!gap-6 z-[1] mqAllMobile:!w-full mq825:gap-[32px] mq450:gap-[16px]`}>
        <div className="self-stretch flex flex-col items-start justify-start gap-[24px] mqAllMobile:gap-2 max-w-[1400px] px-[14px] mqAllMobile:px-2 mq450:self-center">
          <h1 className="m-0 self-stretch relative text-inherit font-bold font-inherit mq825:text-32xl mq450:text-9xl mq450:mt-5 mqAllMobile:!text-xl">
            What we can do for you
          </h1>
          <h3 className="m-0 self-stretch relative text-5xl font-normal font-poppins text-lightgray-100 mqAllMobile:!text-mid">
            We can find you cost-effective resources or deliver you projects directly.
          </h3>
        </div>
        <div className="self-stretch grid grid-cols-[repeat(2,_minmax(427px,_1fr))] gap-6 gap-y-12 mqAllMobile:gap-4 max-w-full text-14xl mq1550:grid-cols-[repeat(2,_minmax(400px,_740px))] mq825:grid-cols-[minmax(100%,_1fr)] mqAllMobile:flex-col mqAllMobile:!w-full">
          {/* <CardColumns
            cardColumnOne="/frame-770722@2x.png"
            staticWebsiteDevelopment="Static Website Development"
            fastSecureAndSEOFriendlyS="Fast, secure, and SEO-friendly static websites to establish your online presence with ease."
            toLink="/static"
          /> */}
          <CardColumns
            cardColumnOne="/pixel-earth.jpg"
            staticWebsiteDevelopment="Offshore Scaling"
            fastSecureAndSEOFriendlyS="The top 1% of global talent is now within your budget, delivering exceptional quality at a fraction of the cost."
            toLink="/offshore"
          />
          <CardColumns
            cardColumnOne="/frame-770722-1@2x.png"
            staticWebsiteDevelopment="Bespoke Software Development"
            fastSecureAndSEOFriendlyS="Dominate your online presence by streamlining your operations with our bespoke web and mobile applications."
            toLink="/bespoke"
          />
          {/* <CardColumns
            cardColumnOne="/frame-770722-1@2x.png"
            staticWebsiteDevelopment="Full Stack Web Development"
            fastSecureAndSEOFriendlyS="Comprehensive web development services from frontend to backend to build robust web applications."
            toLink="/fullstack"
          />
          <CardColumns
            cardColumnOne="/frame-770722-2@2x.png"
            staticWebsiteDevelopment="Bespoke Software"
            fastSecureAndSEOFriendlyS="Custom web designs tailored to your brand identity and business needs to stand out in the market."
            toLink="/bespoke"
          /> */}
        </div>
      </div>
    </div>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent1;
