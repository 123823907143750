import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom"
// import { useBrowser } from "../pages/Provider/BrowserContext";

const FooterTop = ({ className = "" }) => {
  return (
    <div
      className={`self-stretch flex flex-col items-start justify-start max-w-full text-center text-white font-montserrat ${className}`}
    >
      <div className="self-stretch overflow-hidden flex flex-row items-start justify-center py-[100px] mq450:pt-[70px] mq450:pb-[40px] pr-5 pl-[21px] box-border relative gap-[712px] min-h-[626px] mq1250:min-h-[300px] mq1250:pb-[50px]  max-w-full z-[1] mq825:gap-[178px] mq825:pb-[65px] mq825:box-border mq1250:gap-[356px] mqAllMobile:!pt-[48px]">
        <div className="w-[1156px] [backdrop-filter:blur(10px)] [background:linear-gradient(95.76deg,_rgba(255,_255,_255,_0.04),_rgba(255,_255,_255,_0.03)_26.43%,_rgba(255,_255,_255,_0.01)_77.28%,_rgba(255,_255,_255,_0.02))] box-border overflow-hidden shrink-0 flex flex-col items-center justify-start pt-[83px] pb-[37px] mqAllMobile:!py-[30px] pr-[21px] pl-5 gap-[64px] max-w-full z-[1] border-[1px] border-solid border-gray-300 mq825:gap-[16px] mq1250:gap-[32px] mq450:pt-[54px] mq450:pb-6 mq450:box-border">
          <h1 className="m-0 w-[964px] relative text-[50px] font-bold font-inherit inline-block max-w-full mq900:text-13xl mqAllMobile:!text-7xl">
            For more detailed information related to pricing and packaging.
          </h1>
          <div className="w-[964px] flex flex-row items-start justify-center py-0 pr-0 pl-0.5 box-border max-w-full mb-8 mqAllMobile:!mb-0">
            <Link to="/contact" className="no-underline cursor-pointer [border:none] py-2.5 px-5 mqAllMobile:px-5 mqAllMobile:py-1 bg-[transparent] w-[220px] mqAllMobile:w-auto rounded-31xl [background:linear-gradient(132.03deg,_#c471ed,_#a044ff)] overflow-hidden shrink-0 flex flex-row items-start justify-start box-border whitespace-nowrap">
              <div className="flex-1 relative text-5xl mqAllMobile:text-xl font-poppins text-white text-center">
                Contact Us!
              </div>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export const Footer = ({ page }) => {
  const [footerHeight, setFooterHeight] = useState(0);
  // const { windowDimensions: { width } } = useBrowser(); // Destructure width from useBrowser hook
  useEffect(() => {
    const footerElement = document.querySelector('footer');
    const updateFooterHeight = () => {
      if (footerElement) {
        setFooterHeight(footerElement.clientHeight - 9);
      }
    };
    updateFooterHeight();
    window.addEventListener('resize', updateFooterHeight);
    return () => {
      window.removeEventListener('resize', updateFooterHeight);
    };
  }, []);
  return (
    <>
      <div style={{ height: footerHeight }}></div>
      <div className="w-full absolute bottom-0">
        <footer className="self-stretch overflow-hidden flex flex-col items-start justify-start pt-[30px] mqAllMobile:pt-0 px-[91px] pb-[27px] box-border gap-[20px] max-w-full z-[2] mt-[-8.8px] text-left text-base text-darkgray font-montserrat mq825:px-[22px] mq825:box-border mq1250:pl-[45px] mq1250:pr-[45px] mq1250:box-border">
          <div className="self-center w-[1400px] flex flex-row items-end justify-between gap-[20px] mq1250:flex-wrap mq1250:justify-center mq1450:w-full">
            <div className="h-[244px] w-fit mq1024:w-full flex flex-col items-start justify-center gap-[25px] shrink-0 [debug_commit:bf4bc93] mq1024:flex-row mq1024:h-fit mq1024:items-center mq1024:justify-center">
              <Link to="/" className="flex">
                <img
                  className="max-w-[86px] mq1024:h-[114px] relative object-contain mr-28 mq550:mr-0"
                  loading="lazy"
                  alt=""
                  src="/image-1--1-removebgpreview-1@2x.png"
                />
              </Link>
            </div>
            <div className="self-center w-[632.6px] flex flex-col items-start justify-center box-border min-h-full max-w-full">
              <div className="self-stretch flex flex-row items-start justify-between shrink-0 [debug_commit:bf4bc93] mq825:flex-wrap gap-y-[20px] mq550:!mx-0">
                <div className="w-40 flex flex-col items-start justify-start py-0 px-0 box-border gap-[15.3px]">
                  <b className="self-stretch relative text-lg font-semibold text-white">Company</b>
                  <div className="self-stretch relative text-gray-100"><Link className="no-underline text-purple-500" to="/">Home</Link></div>
                  <div className="self-stretch relative text-gray-100"><Link className="no-underline text-purple-500" to="/portfolio">Portfolio</Link></div>
                </div>
                <div className="w-40 flex flex-col items-start justify-start gap-[15.3px]">
                  <b className="w-28 relative text-lg font-semibold text-white flex items-center">Software Development</b>
                  <div className="w-28 relative flex items-center text-gray-100"><Link className="no-underline text-purple-500" to="/static">Static</Link></div>
                  <div className="w-28 relative flex items-center text-gray-100"><Link className="no-underline text-purple-500" to="/fullstack">Full-Stack</Link></div>
                  <div className="w-28 relative flex items-center text-gray-100"><Link className="no-underline text-purple-500" to="/bespoke">Bespoke</Link></div>
                </div>
                <div className="w-40 flex flex-col items-start justify-center gap-[15.3px]">
                  <b className="w-[219.7px] relative text-lg font-semibold text-white flex items-center">Pages</b>
                  <div className="self-stretch relative text-gray-100"><Link className="no-underline text-purple-500" to="/faq">FAQ</Link></div>
                  <div className="self-stretch relative text-gray-100"><Link className="no-underline text-purple-500" to="/contact">Contact Us</Link></div>
                  <div className="self-stretch relative text-gray-100"><Link className="no-underline text-purple-500" to="/offshore">Offshore Scaling</Link></div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full relative text-lgi font-medium text-white text-center flex items-center justify-center max-w-[111%] shrink-0">
            Copyright 2024 VCT.All Reserved
          </div>
        </footer>
      </div>
    </>
  );
};

FooterTop.propTypes = {
  className: PropTypes.string,
};

export default FooterTop;
