import React from "react";
import { useBrowser } from "../pages/Provider/BrowserContext";

const MarqueeComponent = () => {
    const images = [
        // "/harvard.jpg",
        // "/manipal.png",
        // "/tedx.png",
        // "/hindustan.png",
        // "/mercedes.png",
        // "/jp.png",
        // "/kpmg.png",
        // "/A2H-Digital.png",
        { bgColor: "", image: "/vantagelogo.png" },
        { bgColor: "", image: "/ummazinglogo.png" },
        { bgColor: "#bfbfbf", image: "/Drop.png" },
        { bgColor: "", image: "/enconstaffinglogo.png" },
        { bgColor: "", image: "/Gacslogo.jpg" },
        { bgColor: "#000000", image: "/Jaafar.png" },
        { bgColor: "#82ffd8", image: "/P2U.jpg" },
        { bgColor: "#bfbfbf", image: "/A2H-Digital.jpg" },
        { bgColor: "", image: "/showmanslogo.png" },
        { bgColor: "", image: "/Cartsi/Logo.png" },
        { bgColor: "", image: "/AO-Logo.png" },
        { bgColor: "", image: "/thewhitehallcliniclogo.webp" },

    ];
    const w = useBrowser().windowDimensions.width;
    return (
        <div className="w-full overflow-hidden z-10">
            <div className="relative flex max-w-screen overflow-hidden py-5 mt-16 mqAllMobile:mt-10">
                <div className="flex w-max animate-marquee items-center space-x-12">
                    {images.concat(images).map((image, index) => (
                        <div
                            key={index}
                            className={"p-3 h-full flex justify-center items-center w-44 mqAllMobile:w-20 mqAllMobile:p-1 rounded-lg shadow-xl "}
                            style={{
                                backgroundColor: image.bgColor ? image.bgColor : "white",
                                border: "1px solid white"
                            }}
                        >
                            <img
                                className="w-full"
                                src={image.image}
                                alt={image.image.split("/")[1].split(".")[0]}
                                // width={w < 551 ? 70 : 104}
                                width={w < 551 ? 75 : 145}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MarqueeComponent;
