import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { contact } from '../Api/Api';
import { showSwal } from '../Helper/showAlert';

const GetInTouch = ({ className = "", removeGetInTouch = true }) => {

    const InitialContact = {
        name: "",
        phone: "",
        email: "",
        message: ""
    }

    const [contactData, setContactData] = useState(InitialContact)

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const isValidEmail = (email) => {
        let valid = emailRegex.test(email);
        return { valid: valid, value: email, message: valid ? "" : "Invalid Email" };
    };

    const handleUserDetails = async (e) => {
        e.preventDefault()
        if (contactData.name != "" && contactData.email != "" && contactData.message != "") {
            let emailValidity = isValidEmail(contactData.email)
            if (!emailValidity.valid) {
                return showSwal(emailValidity.message, undefined, 400)
            }
            try {
                let response = await contact({
                    ...contactData,
                    phone: contactData?.phone ? contactData.phone : undefined,
                    subject: "SUBJECT RECIEVED"
                })
                setContactData(InitialContact)
                return showSwal("success", "We’ve received your message and will be in touch!", 200)
            } catch (error) {
                console.log(error)
                return showSwal("Failure", error.response.data.message, error.response.data.status)
            }
        } else {
            showSwal("Please fill in all required details", undefined, undefined)
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setContactData((prevVal) => ({ ...prevVal, [name]: value }));
    }

    useEffect(() => {
    }, [contactData])

    return (
        <div className={"self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-45xl " + className}>
            <div className="w-[858px] flex flex-col items-start justify-start gap-[20px] max-w-full pb-[43px]">
                {removeGetInTouch && <div className="self-stretch flex flex-row items-start justify-center py-0 px-5">
                    <h1 className="m-0 relative text-inherit font-bold font-inherit z-[1] mq450:text-19xl mq900:text-32xl text-white font-montserrat mqAllMobile:!text-xl">
                        Get In Touch
                    </h1>
                </div>}
                <form className={`m-0 self-stretch flex flex-col items-start justify-start pt-[${removeGetInTouch ? 80.5 : 30}px] px-[54px] ${removeGetInTouch ? "pb-[70.5px] pt-[55px]" : "pb-[30px]"} box-border relative gap-[20px] mqAllMobile:gap-3 max-w-full z-[1] mq900:pt-[52px] mq900:px-[27px] mq900:pb-[46px] mq900:box-border mqAllMobile:!p-4 bg-gray-800 border-solid border-gray-100 border-[1px] rounded-xl mq900:mx-9 mq750:mx-0`}>
                    {removeGetInTouch && <><div className="flex flex-row items-start justify-start py-0 px-2.5 mqAllMobile:px-1 box-border max-w-full">
                        <h1 className="m-0 relative text-13xl font-normal font-poppins text-gray-600 text-left z-[1] mqAllMobile:!text-lgi mq900:text-7xl">
                            We're here to help your business excel.
                        </h1>
                    </div>
                        <div className="self-stretch flex flex-row items-start justify-start pt-0 pb-[13px] pr-[9px] mqAllMobile:px-1 pl-2.5 box-border max-w-full">
                            <p className="m-0 flex-1 relative text-base font-poppins text-gray-400 text-left inline-block max-w-full z-[1] mqAllMobile:text-smi">
                                Don’t hesitate to reach us! Using the form below drop us a message. We are always happy to have a conversation on anything you may need.
                            </p>
                        </div></>}
                    <div className="w-[718.2px] flex flex-col items-start justify-start pt-0 px-0 pb-[3px] box-border gap-[20px] mqAllMobile:gap-[10px] max-w-full">
                        <div className="self-stretch [backdrop-filter:blur(11.6px)] rounded-3xs bg-gray-800 box-border flex flex-row items-start justify-start pt-[9px] px-[22px] mqAllMobile:px-2 mqAllMobile:py-0 mqAllMobile:rounded-md pb-2 max-w-full z-[1] border-[1px] border-solid border-gray-700">
                            <input
                                className="w-[627.5px] [border:none] [outline:none] font-poppins text-lgi mqAllMobile:text-base bg-[transparent] h-[29px] relative text-gray-400 text-left inline-block max-w-full p-0 z-[1]"
                                name="name" placeholder="Name*" value={contactData.name} onChange={handleChange}
                                type="text"
                            />
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[20px] max-w-full mq900:flex-wrap mqAllMobile:gap-[10px]">
                            <div className="flex-[0.9744] [backdrop-filter:blur(11.6px)] rounded-3xs bg-gray-800 box-border flex flex-row items-start justify-start pt-[11px] px-[21px] mqAllMobile:px-2 mqAllMobile:py-0 mqAllMobile:rounded-md pb-1.5 min-w-[227px] shrink-0 [debug_commit:bf4bc93] max-w-full z-[1] border-[1px] border-solid border-gray-700 mq900:flex-1">
                                <input
                                    className="w-full [border:none] [outline:none] font-poppins text-lgi mqAllMobile:text-base bg-[transparent] h-[29px] flex-1 relative text-gray-400 text-left inline-block min-w-[183px] p-0 z-[1]"
                                    name="email" placeholder="Email Address*" value={contactData.email} onChange={handleChange}
                                    type="text"
                                />
                            </div>
                            <div className="flex-1 [backdrop-filter:blur(11.6px)] rounded-3xs bg-gray-800 box-border flex flex-row items-start justify-start pt-2 px-[17px] mqAllMobile:px-2 mqAllMobile:py-0 mqAllMobile:rounded-md pb-2.5 min-w-[227px] shrink-0 [debug_commit:bf4bc93] max-w-full z-[1] border-[1px] border-solid border-gray-700 mq900:flex-1">
                                <input
                                    className="w-full [border:none] [outline:none] font-poppins text-lgi mqAllMobile:text-base bg-[transparent] h-[29px] flex-1 relative text-gray-400 text-left inline-block min-w-[188px] p-0 z-[1]"
                                    placeholder="Mobile Number"
                                    name="phone" value={contactData.phone} onChange={handleChange}
                                    type="number"
                                />
                            </div>
                        </div>
                        <div className="self-stretch h-[130px] mqAllMobile:h-[80px] [backdrop-filter:blur(11.6px)] rounded-3xs bg-gray-800 box-border flex flex-row items-start justify-start py-[19px] px-4 mqAllMobile:px-2 mqAllMobile:py-0 mqAllMobile:rounded-md gap-[25px] max-w-full z-[1] border-[1px] border-solid border-gray-700">
                            <input
                                className="w-full [border:none] [outline:none] font-poppins text-lgi mqAllMobile:text-base bg-[transparent] h-[29px] flex-1 relative text-gray-400 text-left inline-block min-w-[250px] max-w-full p-0 z-[1]"
                                name="message" placeholder="Your Query Message Here*" value={contactData.message} onChange={handleChange}
                                type="text"
                            />
                        </div>
                    </div>
                    <button className="cursor-pointer [border:none] py-2.5 px-[15px] bg-darkorchid-200 rounded-3xs flex flex-row items-start justify-start z-[1] hover:bg-darkorchid-300">
                        <div className="relative text-xl font-poppins text-white text-center inline-block min-w-[71px] mq450:text-base" onClick={handleUserDetails} >
                            Submit
                        </div>
                    </button>
                </form>
            </div>
        </div>
    )
}

GetInTouch.propTypes = {
    className: PropTypes.string,
};

export default GetInTouch