import { Footer } from "../components/FooterTop";
import Header from "../components/Header";
import PricingStartsFrom from "../components/PricingStartsFrom.js";
import Plans from "../components/Plans.js";
import GetInTouch from "../components/GetInTouch.js";

const PricingHero = ({ className = "" }) => {
    return (
        <div
            className={`self-stretch overflow-hidden flex flex-col items-start justify-start py-[27px] px-[400px] mq1550:px-[300px] mq1325:px-[200px] box-border max-w-full z-[1] text-center text-45xl text-light-100 font-montserrat lg:box-border mq825:px-[100px] mq575:px-[20px] mq825:box-border ${className}`}
        >
            <div className="self-center flex flex-col items-center justify-start gap-[71px] max-w-full lg:gap-[35px] mq825:gap-[18px]">
                <div className="self-stretch flex flex-col items-center justify-start py-0 px-5 box-border gap-[49px] max-w-full mq825:gap-[24px]">
                    <h1 className="m-0 relative text-inherit leading-[72px] font-extrabold font-inherit inline-block max-w-full mq450:text-19xl mq450:leading-[43px] mq825:text-32xl mq825:leading-[58px]">
                        Pricing and Packages
                    </h1>
                    <div className="w-[95.5px] h-3 relative hidden">
                        <div className="absolute h-full w-[74.87%] top-[0%] right-[0%] bottom-[0%] left-[25.13%] rounded-mid [background:linear-gradient(152.01deg,_#b2ebf2,_#d1c4e9_65.35%,_#f8bbd0)]" />
                        <div className="absolute h-full w-[12.57%] top-[0%] right-[87.43%] bottom-[0%] left-[0%] rounded-[50%] [background:linear-gradient(152.01deg,_#b2ebf2,_#d1c4e9_65.35%,_#f8bbd0)]" />
                    </div>
                </div>
                <p className="max-w-[1400px] m-0 self-stretch h-fit relative text-xl mqAll:!w-[900px] mq1157:w-full leading-[36px] font-poppins inline-block shrink-0 mq450:text-lgi mq450:leading-[29px] mq575:!text-base ">
                    Congratulations on making the first step into giving your business the
                    ‘WOW’ website it needs. Let’s take you through the options below to
                    get you set up!
                </p>
            </div>
        </div>
    );
};

const MyWorkings = () => {

    const contentValues = [
        "Content Upload",
        "Opt-in Forms",
        "SEO",
        "Admin and User Portals",
        "Company Emails",
        "E-commerce",
        "Google Analytics",
        "Plugins or Extensions",
        "Social Media Icons",
        "Payment Processing",
        "Database Integration",
        "Bespoke Functionalities"
    ];

    const renderButton = (content) => (
        <button className="cursor-pointer w-[300px] [border:none] pt-[29.9px] px-[67px] pb-[26.9px] bg-darkslateblue-200 flex-1 !m-[0] absolute top-[0px] left-[0px] rounded-[11.07px] flex flex-row items-start justify-center whitespace-nowrap hover:bg-darkslateblue-100 bg-[#2A163F]">
            <div className="relative text-3xl-6 font-poppins text-light-100 text-center">
                {content}
            </div>
        </button>
    );

    const chunkedContentValues = contentValues.reduce((acc, curr, index) => {
        const chunkIndex = Math.floor(index / 4);
        if (!acc[chunkIndex]) {
            acc[chunkIndex] = []; // start a new chunk
        }
        acc[chunkIndex].push(curr);
        return acc;
    }, []);

    return (
        <>
            <div className="w-full bg-[url('./img/Rectangle_841.png')] h-[400px] mb-24 bg-cover bg-center z-[2] mq750:hidden">
                <div className="self-stretch h-[447px] flex flex-row items-start justify-center box-border bg-cover bg-no-repeat bg-[top] max-w-full z-[1] text-center text-3xl-6 text-light-100 font-poppins mq450:pt-11 mq450:pb-11 mq450:box-border mq1450:pl-[22px] mq1450:box-border">
                    <div className="h-[300px] w-[1393.5px] flex flex-row flex-wrap items-center justify-center relative gap-[1px] max-w-full z-[1]">
                        {contentValues.map((content, buttonIndex) => (
                            <div key={buttonIndex} className="w-[326.6px] !m-[0] relative">
                                {renderButton(content)}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="hidden mq550:hidden mq750:block w-full z-[2] h-[calc(100vw_/_1924*633)] bg-center bg-contain bg bg-[url('./img/AllWorkingPricing.png')] mb-24" />
            <div className="hidden mq550:block relative h-[calc(100vw_/_1149*_1637)] w-full max-w-[1149px] bg-center bg-no-repeat bg-cover bg-[url('./img/AllServices.png')] mb-24" />
        </>
    );
};



const PricingPage = () => {
    return (
        <div className="w-full relative min-h-screen bg-black overflow-hidden flex flex-col items-start justify-start leading-[normal] tracking-[normal]">
            <div className="w-[2487px] h-[7910px] absolute !m-[0] bottom-[-3620px] left-[-291px] flex items-center justify-center z-[0]">
                <img
                    className="w-full h-full object-contain absolute left-[0px] top-[-86px] [transform:scale(1.022)]"
                    alt=""
                    src="/element.svg"
                />
            </div>
            <Header />
            <PricingHero />
            <Plans />
            <PricingStartsFrom className="mb-24" />
            <MyWorkings />
            <GetInTouch />
            <Footer page={"static"} />
        </div>
    );
};

export default PricingPage