import PropTypes from "prop-types";
import { Link } from 'react-router-dom'

const InfoColumns = ({ className = "" }) => {
  return (
    <div
      className={`self-stretch flex flex-row items-start justify-between py-0 px-1.5 box-border max-w-full gap-[20px] mqAllMobile:gap-0 z-[1] text-left text-xl text-lightgray-200 mq1550:flex-wrap mqAllMobile:px-0 ${className}`}
    >
      <div className="w-[599px] flex flex-col items-start justify-start gap-[19px] mqAllMobile:gap-0 min-w-[599px] max-w-full mq825:min-w-full mq1550:flex-1">
        <div className="w-[579px] flex flex-row items-end justify-start gap-[20.5px] mqAllMobile:gap-1 max-w-full mq825:flex-wrap">
          <div className="h-[39px] w-[49.8px] mqAllMobile:w-[40px] relative flex items-center shrink-0 mq450:text-base">
            VCT
          </div>
          <div className="flex-1 flex flex-col items-start justify-end pt-0 px-0 pb-[14.6px] box-border min-w-[260px] max-w-full">
            <div className="self-stretch h-px relative box-border border-t-[1px] border-solid border-lightgray-200" />
          </div>
        </div>
        <h1 className="m-0 self-stretch h-[238px] mq1375:h-fit relative text-28l font-bold font-montserrat text-white flex items-center shrink-0 mq825:text-19xl mqAllMobile:!text-xl">
          Benefit from lower costs while giving opportunities at the same time.
        </h1>
      </div>
      <div className="w-[705px] flex flex-col items-start justify-start pt-[15.5px] px-0 pb-0 box-border min-w-[705px] max-w-full text-justify text-3xl text-lightgray-100 font-poppins mq825:min-w-full mq1550:flex-1">
        <div className="self-stretch flex flex-col items-start justify-start gap-[24px] mqAllMobile:gap-4">
          <p className="m-0 self-stretch h-[195px] mq1375:h-fit relative flex items-center shrink-0 mqAllMobile:!text-lg">
            Our expertise lies in hiring the top 1% of talent offshore, to battle the overwhelming costs that can prevent you from scaling and growing your business.
          </p>
          <button className="cursor-pointer py-2 px-[29px] mqAllMobile:px-4 mqAllMobile:py-1 bg-[transparent] w-[200px] rounded-31xl box-border overflow-hidden flex flex-row items-start justify-start whitespace-nowrap border-[1px] border-solid border-white hover:bg-gainsboro-300 hover:box-border hover:border-[1px] hover:border-solid hover:border-gainsboro-100">
            <Link to={"/contact"} className="flex-1 relative text-mid font-poppins text-white text-center no-underline">
              Contact Us
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
};

InfoColumns.propTypes = {
  className: PropTypes.string,
};

export default InfoColumns;
