import React, { useEffect, useRef, useState } from 'react'
import "./style.css"

function AnimateComponentScroll({ ourProcessContent }) {
    const elementControl = useRef([]);

    const applyFlickerEffect = (element1, element2, timings) => {
        // let toggleIndex = 0;
        // const toggleFlicker = () => {
        //     if (toggleIndex % 2 === 0) {
        //         element1.classList.add('fade-inactive');
        //         element2.classList.add('blur-[5px]');
        //     } else {
        //         element1.classList.remove('fade-inactive');
        //         element2.classList.remove('blur-[5px]');
        //     }
        //     toggleIndex += 1;
        //     if (toggleIndex < timings.length) {
        //         const nextTimeout = timings[toggleIndex % timings.length];
        //         setTimeout(toggleFlicker, nextTimeout);
        //     } else {
        //         element1.classList.remove('fade-inactive');
        //         element2.classList.remove('blur-[5px]');
        //     }
        // };
        // if (timings?.length > 0) {
        //     toggleFlicker();
        // }
        setTimeout(() => { element1.classList.add('fade-inactive'), element2.classList.add('blur-[5px]') }, timings?.reduce((x, y) => x + y, 0));
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    const imageRef = entry.target;
                    if (entry.isIntersecting) {
                        const scrollAnimate = imageRef.querySelector('.scroll-animate');
                        const backImage = imageRef.querySelector('.back-image')
                        if (scrollAnimate) {
                            setTimeout(() => {
                                applyFlickerEffect(imageRef, backImage);
                            }, 500)
                            scrollAnimate.style.transform = "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)";
                        } else {
                            applyFlickerEffect(imageRef, backImage);
                        }
                    }
                });
            },
            {
                threshold: 0.6
            }
        );

        elementControl.current.forEach(ref => {
            if (ref) observer.observe(ref);
        });

        return () => {
            elementControl.current.forEach(ref => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, []);

    return (
        <div className="container w-container">
            <div className="content-block">
                <div className="steps-clone">
                    {ourProcessContent.map((step, index) => (
                        <div
                            key={index}
                            id={`website-bg-${index}`}
                            className="mx-auto relative h-full"
                            ref={el => (elementControl.current[index] = el)}
                        >
                            <div
                                className={`back-image mqAllMobile:!hidden absolute top-0 left-0 h-full w-full bg-cover zoom-fade`}
                                style={{ background: `linear-gradient(0deg, #af57f73a, #00000040), url(${step?.image}) no-repeat top / cover` }}  // Dynamic background image
                            />
                            {index % 2 == 0 ? <div className="step-block">
                                {index !== 0 && <div className="scoll-wrap">
                                    <div className={"scroll-animate duration-[500ms] ease-in-out " + index}
                                        style={{ transform: "translate3d(0px, 0px, 0px) scale3d(1, 0, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)", transformStyle: "preserve-3d" }}>
                                    </div>
                                    <div className="scroll-base"></div>
                                </div>}
                                <div className="content-wrap">
                                    <div className="step-content-block">
                                        <div className="step flex-1 relative shadow-[0px_6.7px_11.16px_rgba(0,_0,_0,_0.03)] bg-[rgba(217, 217, 217, 0.8)] box-border py-10 px-[34px] mqAllMobile:px-[14px] min-w-[350px] border-[1.1px] border-solid border-light-100 mqAllMobile:py-[20px] mq450:box-border fade-in mq900:min-w-full opacity-40 transition-opacity duration-300 ease-in-out">
                                            <div className="text-block">
                                                <div className="content" style={{ color: "#ffffff" }}>
                                                    <div className="heading">{step.title}</div>
                                                    <p className='mqAllMobile:text-smi'>{step.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="dot" style={{ backgroundColor: "rgb(168,84,247)", width: "24px", height: "24px" }}></div>
                                <div className="img-block" style={{ opacity: 1 }}>
                                    <img className='myImageBgShadow h-[432px] w-[432px] mq750:h-96 mq750:w-96 mqAllMobile:!h-64 mqAllMobile:!w-64 min-w-[308px] object-cover rounded-xl opacity-40 transition-opacity duration-300 ease-in-out' src={step?.bg} loading="lazy" alt={`${step.title} illustration`} />
                                </div>
                            </div> : <div className="step-block">
                                <div className="img-block" style={{ opacity: 1 }}>
                                    <img className='myImageBgShadow h-[432px] w-[432px] mq750:h-96 mq750:w-96 mqAllMobile:!h-64 mqAllMobile:!w-64 min-w-[308px] object-cover rounded-xl opacity-40 transition-opacity duration-300 ease-in-out' src={step?.bg} loading="lazy" alt={`${step.title} illustration`} />
                                </div>
                                <div className="dot desktop" style={{ backgroundColor: "rgb(168,84,247)", width: "24px", height: "24px" }}></div>
                                <div className="content-wrap">
                                    <div className="step-content-block">
                                        <div className="step flex-1 relative shadow-[0px_6.7px_11.16px_rgba(0,_0,_0,_0.03)] bg-[rgba(217, 217, 217, 0.8)] box-border py-10 px-[34px] mqAllMobile:px-[14px] min-w-[350px] border-[1.1px] border-solid border-light-100 mqAllMobile:py-[20px] mq450:box-border fade-in mq900:min-w-full opacity-40 transition-opacity duration-300 ease-in-out">
                                            <div className="text-block">
                                                <div className="content" style={{ color: "#ffffff" }}>
                                                    <div className="heading">{step.title}</div>
                                                    <p className='mqAllMobile:text-smi'>{step.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="dot mobile" style={{ backgroundColor: "rgb(168,84,247)", width: "24px", height: "24px" }}></div>
                                <div className="scoll-wrap">
                                    <div className={"scroll-animate duration-[500ms] ease-in-out " + index}
                                        style={{ transform: "translate3d(0px, 0px, 0px) scale3d(1, 0, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)", transformStyle: "preserve-3d" }}>
                                    </div>
                                    <div className="scroll-base"></div>
                                </div>
                            </div>}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default AnimateComponentScroll